import React from "react";
import AfterActivate from "../Assets/images/woocommerce/AfterActivate.JPG";
import SettingsMenu from "../Assets/images/woocommerce/SettingsMenu.png";
import SettingsPage from "../Assets/images/woocommerce/SettingsPage-resp.png";
import IdCode from "../Assets/images/woocommerce/IdCode-resp.png";
import CheckoutExample from "../Assets/images/woocommerce/CheckoutExample.png";
import Activate from "../Assets/images/woocommerce/Activate.JPG";
import Qr from "../Assets/images/woocommerce/qr.JPG";
import Bnc from "../Assets/images/woocommerce/banque.JPG";

const Woocommerce = () => {
return (
    <div>

        <div className="flex lg:flex-col md:flex-col justify-center items-center lg:p-14 md:p-14 sm:py-5 bg-effyis-purple">
            <h5 className="text-white font-thin tracking-wider lg:text-5xl md:text-4xl sm:text-xl lg:p-6 md:p-6 sm:p-6 sm:text-center">Lodin plugin pour WooCommerce</h5>
        </div>

        <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
            <p>Ce plugin permet d'ajouter nos méthodes de paiement sous forme d'un bouton à votre boutique WooCommerce.</p>
            <p className="mb-4">En choisissant Lodin comme moyen de paiement, vous offrez à vos clients la possibilité de payer par virement bancaire. 
            Notre application porte cette expérience à un niveau supérieur : en quelques clics seulement, les paiements sécurisés de vos clients seront effectués en un clin d'œil.</p>
            <p className="text-effyis-purple"><span className="font-bold text-gray-900">NB:</span> Ce plugin nécessite au minimum Woocommerce 6.2.</p>
        </div>

        <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Installation</h2>

        <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
            <p className="mb-4">Ce plugin est disponible sur wordpress.org: <a className="text-effyis-purple hover:underline">wordpress.org/plugins/Lodin/</a></p>
            <p>Après avoir l'installer, il faut accéder à l'interface des plugins pour pouvoir l'activer si vous ne l'avez pas fait après l'installation.</p>
            <img className="max-w-full h-auto my-5" src={Activate} alt="Activation" />
        </div>

        <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Configuration</h2>

        <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
            <p className="mb-4">Il va falloir se diriger vers la page des paramètres en appuyant sur "settings" - après l'activation du plugin - pour pouvoir saisir les informations de votre compte Lodin.</p>
            <img className="max-w-full h-auto my-5" src={AfterActivate} alt="Paramètres" />
            <p className="mb-4">Vous allez pouvoir donc voir votre plugin Lodin dans la barre du menu à gauche en bas avec deux options:</p>
            <p className="lg:ml-6 md:ml-6 mb-2 sm: ml-0"><span className="font-bold text-gray-900">Paramètres généraux:</span> Un interface pour la configuration de votre compte Lodin, un deuxième des mises à jour, et un troisième à propos de nous.</p>
            <p className="lg:ml-6 md:ml-6 mb-2 sm: ml-0"><span className="font-bold text-gray-900">Contactez-nous:</span> Un interface contenant des informations pour pouvoir nous contacter.</p>    
            <img className="max-w-full h-auto my-5" src={SettingsMenu} alt="Paramètres Menu" />
            <p className="mb-4">Dans la page de configuration, vous trouverez deux champs à remplir avec <span className="text-effyis-purple">l'identifiant</span> et <span className="text-effyis-purple">le code secret</span> de votre compte Lodin.</p>
            <img className="max-w-full h-auto my-5 w-11/12" src={SettingsPage} alt="Page de configuration" />
            <p className="mb-4">Ensuite, il faut cocher la case d'acceptation des conditions d'utilisation puis appuyer sur le bouton "Enregistrer".</p>
            <img className="max-w-full h-auto my-5 w-11/12" src={IdCode} alt="Page de configuration" />
            <p className="mb-4">Après la vérification et la validation de vos données, ils vont être enregistrées et le bouton de paiement Lodin s'affichera dans l'interface du paiement au dessus du panier.</p>
            <img className="max-w-full h-auto my-5 w-4/7" src={CheckoutExample} alt="Page du panier" />
        </div>

        <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Paiement</h2>

        <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
            <p className="mb-4">En cliquant sur le bouton Lodin, vous allez être redirigé vers un interface contenant les informations de la commande.. etc.</p>
            <img className="max-w-full h-auto my-5" src={Qr} alt="Paramètres" />
            <p className="mb-4">Après, il faut choisir la banque que vous souhaitez payer avec, choisir votre compte et terminer la procédure du paiement.</p>
            <img className="max-w-full h-auto my-5" src={Bnc} alt="Paramètres" />
        </div>

    </div>
);
}

export default Woocommerce;