import React from "react";
import ModuleModules from "../Assets/images/prestashop/ModuleModules.PNG";
import ModuleCatalogueEffyis from "../Assets/images/prestashop/ModuleCatalogueEffyis.PNG";
import ModuleConfigParams from "../Assets/images/prestashop/ModuleConfigParams.PNG";
import ModuleConfigLogin from "../Assets/images/prestashop/ModuleConfigLogin.PNG";
import ModuleManagerEffyis from "../Assets/images/prestashop/ModuleManagerEffyis.PNG";
import Successparams from "../Assets/images/prestashop/Successparams.PNG";
import PaybuttonChecked from "../Assets/images/prestashop/PaybuttonChecked.PNG";
import myticket from "../Assets/images/prestashop/myticket.PNG";
import Banques from "../Assets/images/prestashop/Banques.PNG";
const Prestashop = () => {
    return (
        
        <div>
    
            <div className="flex lg:flex-col md:flex-col justify-center items-center lg:p-14 md:p-14 sm:py-5 bg-effyis-purple">
                <h5 className="text-white font-thin tracking-wider lg:text-5xl md:text-4xl sm:text-xl lg:p-6 md:p-6 sm:p-6 sm:text-center">Module Lodin pour Prestashop</h5>
            </div>
            <div className='flex justify-center flex-row'>
            <div className='lg:w-3/4 sm:w-full sm:mx-10'>
            <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
                <p>Le module Lodin permet aux marchands ayant leurs sites e-commerce sur la plateforme Prestashop d'exploiter les méthodes de paiement Lodin en intégrant ce dernier.</p>
                <p className="mb-4">En choisissant Lodin comme moyen de paiement, vous offrez à vos clients la possibilité de payer par virement bancaire. 
                En quelques clics seulement, vos clients pourront choisir la banque et le compte avec lesquels ils veulent payer. Les paiements de vos clients seront sécurisés, rapides et fiable.</p>
                <p className="text-effyis-purple"><span className="font-bold text-gray-900">NB:</span> Ce module nécessite au minimum Prestashop 1.6</p>
            </div>
    
            <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Installation</h2>
    
            <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
                <p className="mb-4">Vous pouvez télécharger ce module via votre Back-Office dans la rubrique "Module Catalogue" ou dans le marketplace Prestashop <a className="text-effyis-purple hover:underline" >https://addons.prestashop.com/fr/</a></p>
                <p>La rubrique "Modules Catalogue" se situe au menu prestashop dans "Modules".</p>
                <img width="200" className="max-w-full h-auto mx-auto my-5" src={ModuleModules} alt="Activation" />
                <p className="mb-4">Après avoir accéder au "Modules Catalogue", cherchez le nom du module dans la barre de recherche, et installez ce dernier.</p>
                <img width="700" className="max-w-full h-auto mx-auto my-5" src={ModuleCatalogueEffyis} alt="Paramètres Menu" />

            </div>
    
            <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Configuration</h2>
    
            <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
                <p className="mb-4">Une fois le module est installé, dirigez vous vers la page "Module Manager", vous trouverez la globalité des modules que vous utilisez, cherchez le module Lodin et cliquez sur "configure" pour configuer votre compte Marchand.</p>
                <img width="700" className="max-w-full h-auto mx-auto my-5" src={ModuleManagerEffyis} alt="Page de configuration" />
                <p className="mb-4">La Page de configuration se compose de deux rubriques, la première est consacrée aux informations à propos de la solution Lodin, il est nécessaire d'avoir un compte marchand Lodin pour utiliser le module, si vous n'avez pas de compte, prière d'en créer un en cliquant sur le boutton "Créer un compte!" .</p>
                <img width="700" className="max-w-full h-auto mx-auto my-5" src={ModuleConfigLogin} alt="Page de configuration" />
                <p className="mb-4">La deuxième rubrique appelée "Paramètres", contient deux champs où vous devez renseigner votre <span className="text-effyis-purple">identifiant</span> et votre <span className="text-effyis-purple">code secret</span> de votre compte Lodin. Le champs "Etat" spécifie votre état d'inscription, ce champs vous aide à avoir une idée si vos identifiants sont toujours valables, ou si vous devez vous inscrire une autre fois en cas d'expiration.</p>
                <img width="700" className="max-w-full h-auto mx-auto my-5" src={ModuleConfigParams} alt="Page du panier" />
                <p className="mb-4">Après la vérification et la validation de vos données, ils vont être enregistrées et le message de confirmation suivant va s'afficher.</p>
                <img width="400" className="max-w-full h-auto mx-auto my-5" src={Successparams} alt="Page du panier" />
            </div>
    
            <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Parcours de Paiement</h2>
    
            <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
                <p className="mb-4">Après que vos client vont finaliser le choix des articles, et procéder au paiement, l'option "Pay by Lodin" s'affiche parmis les méthodes de paiement que vous autorisez, il suffit de cocher les conditions générales et cliquer sur "Place Order".</p>
                <img width="400" className="max-w-full h-auto mx-auto my-5" src={PaybuttonChecked} alt="Paramètres"/>
                <p className="mb-4">En cliquant sur le bouton "Place Order", vous allez être redirigé vers une interface contenant un ticket avec les informations concernant votre commande, notamment le total, l'identifiant du ticket et le marchand, confirmez votre commande en cliquant sur "Passer au Paiement".</p>
                <img width="550" className="max-w-full h-auto mx-auto my-5" src={myticket} alt="Paramètres"/>
                <p className="mb-4">En cliquant sur le bouton "Passer Au Paiement", il faut choisir la banque que vous souhaitez payer avec, choisir votre compte bancaire, et finaliser la procédure de paiement, si le paiement est valide, le client sera redirigé vers la page de confirmation de votre Shop, sinon il revient vers la page de paiement.</p>
                <img width="400" className="max-w-full h-auto mx-auto my-5" src={Banques} alt="Paramètres"/>
            </div>
    
        </div>
        </div>
        </div>
    );
}

export default Prestashop;