import React, { useState } from 'react';

export default function WizardSuccess() {
    const [descriptionPaiement, setDescriptionPaiement] = useState('');
    const [descriptionRemboursement, setDescriptionRemboursement] = useState('');

return (
    
    <div className="flex flex-col justify-start  items-center h-full  pb-5  bg-white-almost ">
        <div style={{zoom: '100%', width:540}}
        >
            <div className="bg-white rounded-lg p-10 flex items-center shadow justify-center">
                <div>
                    <svg className="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/></svg>

                    <h2 className="text-2xl mb-4 text-gray-800 text-center font-bold">Compte ajouté avec succés</h2>

                    <div className="text-gray-600 mb-8 text-center">
                        Actualisez la page S.V.P
                    </div>
                </div>
            </div>
        </div>
        
    </div>
)
}

