import React from "react";

import ApiCard from "../Cards/ApiCard";
import { Link } from "react-router-dom";

// ===========================|| Search Documentation Page ||=========================== //
const produits = [
  {
    type: 'produit',
    productName: 'Produit Marchand',
    publishDate:'30/09/2021',
    updateDate:'26/10/2021',
    description:"Ce produit permet d'effectuer les opérations concernant le marchant: création d'une commande, consulter les informations marchand, génération d'un access token.",
    version: '1.0.0',
    state:'published', // or published
    apis:[
      {
          id: 2,
          type: 'api',
          productName: 'API Marchand',
          version: '1.0.0',
          state:'published', // deprecated or published
      },
      {
          id: 3,
          type: 'api',
          productName: 'API Authentification',
          version: '1.0.0',
          state:'published', // deprecated or published
      },
    ]
  },
  {
    type: 'produit',
    productName: 'Produit Banque',
    publishDate:'30/09/2021',
    updateDate:'26/10/2021',
    description:"Ce produit permet d'effectuer les opérations concernant la banque: création requête de paiement, consulter les banques compatibles, consulter les informations des comptes bancaires, ainsi que l'hisorique des transactions pour chaque compte.",
    version: '1.0.0',
    state:'published', // or published
    apis:[
      {
          id: 1,
          type: 'api',
          productName: 'API PISP',
          version: '1.0.0',
          state:'published', // deprecated or published
      },
      {
          id: 5,
          type: 'api',
          productName: 'API AISP',
          version: '1.0.0',
          state:'published', // deprecated or published
      },
      {
          id: 3,
          type: 'api',
          productName: 'API Authentification',
          version: '1.0.0',
          state:'published', // deprecated or published
      },
    ]
  },
  
]

const apis = [
  {
    id: 1,
    type: 'api',
    productName: 'API PISP',
    version: '1.0.0',
    state:'deprecated', // deprecated or published
  },
  {
      id: 5,
      type: 'api',
      productName: 'API AISP',
      version: '1.0.0',
      state:'published', // deprecated or published
  },
  {
      id: 3,
      type: 'api',
      productName: 'API Authentification',
      version: '1.0.0',
      state:'published', // deprecated or published
  },
  {
    id: 2,
    type: 'api',
    productName: 'API Marchand',
    version: '1.0.0',
    state:'published', // deprecated or published
  },
  {
      id: 3,
      type: 'api',
      productName: 'API Authentification',
      version: '1.0.0',
      state:'published', // deprecated or published
  },
  
]


const ApiCardRenderer  = ({tagsSelected, searchedWord}) => {
    function searchWord (product, word)  {
        if(product.productName.toLowerCase().includes(word.toLowerCase()) || product.version.toLowerCase().includes(word.toLowerCase())){
            return true
        }else{
            return false
        }
    }

    if ((tagsSelected.filter(e => e.name === 'Déprécié').length > 0 && tagsSelected.filter(e => e.name === 'Publié').length == 0 ) ){
        return(
        apis.filter(e => e.state === 'deprecated').filter(e => searchWord(e, searchedWord) ).map((item, index)=>
            <Link key={index} to={'/ApiDetailsPage/'+item.id}>
                <ApiCard productDetails={item}/>
            </Link>)
        )
    }else if ( (tagsSelected.filter(e => e.name === 'Déprécié').length == 0 && tagsSelected.filter(e => e.name === 'Publié').length > 0 ) ){
        return(
        apis.filter(e => e.state === 'published').filter(e => searchWord(e, searchedWord)).map((item, index)=>
            <Link key={index} to={'/ApiDetailsPage/'+item.id}>
                <ApiCard productDetails={item}/>
            </Link>)
        )
    }else{
        return(
        apis.filter(e => searchWord(e, searchedWord)).map((item, index)=>
            <Link key={index} to={'/ApiDetailsPage/'+item.id}>
                <ApiCard productDetails={item}/>
            </Link>)
        )
    }
    
}

const ProduitCardRenderer  = ({tagsSelected, searchedWord}) => {
    function searchWord (product, word)  {
        if(product.productName.toLowerCase().includes(word.toLowerCase()) || product.version.toLowerCase().includes(word.toLowerCase())){
            return true
        }else{
            return false
        }
    }
    if ((tagsSelected.filter(e => e.name === 'Déprécié').length > 0 && tagsSelected.filter(e => e.name === 'Publié').length == 0 ) ){
        return(
        produits.filter(e => e.state === 'deprecated').filter(e => searchWord(e, searchedWord)).map((item, index)=>
            <div role="cell" className="bg-gray-100">
            <Link to={"/ProductDetail/" + (index + 1)}>
                <ApiCard productDetails={item} />
            </Link>
            </div>
            )
        )
    }else if ( (tagsSelected.filter(e => e.name === 'Déprécié').length == 0 && tagsSelected.filter(e => e.name === 'Publié').length > 0 ) ){
        return(
        produits.filter(e => e.state === 'published').filter(e => searchWord(e, searchedWord)).map((item, index)=>
            <div role="cell" className="bg-gray-100">
            <Link to={"/ProductDetail/" + (index + 1)}>
                <ApiCard productDetails={item} />
            </Link>
            </div>
            )
        )
    }else{
        return(
        produits.filter(e => searchWord(e, searchedWord)).map((item, index)=>
            <div role="cell" className="bg-gray-100">
            <Link to={"/ProductDetail/" + (index + 1)}>
                <ApiCard productDetails={item} />
            </Link>
            </div>
            )
        )
    }
}

const ProductContainer = ({tagsSelected, searchedWord}) => {
    React.useEffect(() => {
        
      }, [tagsSelected, searchedWord]);
    return(
        <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 pb-6 gap-4  ">
            {
            (tagsSelected.filter(e => e.name === 'Produit').length > 0  ) || (tagsSelected.filter(e => e.name === 'Produit').length == 0 && tagsSelected.filter(e => e.name === 'API').length == 0 )? 
            <ProduitCardRenderer tagsSelected={tagsSelected} searchedWord={searchedWord}/>
            :
            <></>
            }
            {
            ( tagsSelected.filter(e => e.name === 'API').length > 0 ) || (tagsSelected.filter(e => e.name === 'Produit').length == 0 && tagsSelected.filter(e => e.name === 'API').length == 0 ) && searchedWord.length > 1?
                <ApiCardRenderer tagsSelected={tagsSelected} searchedWord={searchedWord} />
            :
                <></>
            }
        </div>
    );
};

export default ProductContainer;
