import { ReactComponent as Rocker } from "../../../Assets/svg/rocket-ship-svgrepo-com.svg";
import { ReactComponent as Light } from "../../../Assets/svg/light-bulb-svgrepo-com.svg";
import { ReactComponent as Explore } from "../../../Assets/svg/explore-svgrepo-com.svg";
import {Link} from 'react-router-dom'

export default function Example() {
  return (
    <>
      <div className="flex flex-col justify-start items-center">
        <div>
          <h1 className="text-white font-extrabold  lg:text-4xl md:text-4xl sm:text-3xl  text-center sm:-mt-6 lg:mt-5 lg:my-3 md:mt-5 md:my-3 sm:px-4 ">
            {/* Build The Next-Gen Payment with Effyis */}
            Développez la nouvelle génération de paiement avec Lodin
          </h1>
          <h2 className="text-center text-white font-medium text-2xl mb-3 mt-8 sm:px-10">
            Simple, secure, irrevocable, instantaneous, convenient and cost
            effective.{" "}
          </h2>
          <h2 className="text-center text-white font-medium text-2xl">
            Créez, testez et innovez!
          </h2>
        </div>
        <a
          href="#"
          className="my-8 whitespace-nowrap inline-flex items-center justify-center px-10 py-2.5 border border-transparent 
                          rounded shadow-sm text-base  text-violet-700 "
        ></a>
      </div>
      <div className=" relative -top-4 p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-5  ">
        <div className="rounded overflow-hidden shadow-xl bg-white">
          <Explore className="h-20 w-20 mr-auto ml-auto m-2 p-2" />
          <hr className="my-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="px-6 py-4  ">
            <div className="font-bold text-xl mb-2 text-center">
              Découvrez nos{" "}
              <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-effyis-purple relative inline-block">
                <span className="relative text-white m-1 p-1">APIS</span>
              </span>
            </div>
            <p className="text-gray-700 text-center p-5 ">
              Vous avez un défi commercial ? Explorez nos produits API,
              découvrez la valeur qu'ils offrent et apprenez à les utiliser.
              <br /> Nous avons hâte de voir ce que vous créez !{" "}
            </p>
          </div>
          <div className=" flex flex-col justify-start items-center">
            <Link to="/SearchDocLandingPage" className="rounded-lg px-4 py-2  bg-white  text-effyis-purple hover:bg-effyis-purple hover:text-white duration-300">
              Voir nos produits
            </Link>
          </div>
        </div>
        <div className="rounded overflow-hidden shadow-xl bg-white ">
          <Rocker className="h-20 w-20 mr-auto ml-auto m-2 p-2" />
          <hr className="my-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />

          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 text-center">
              Comment{" "}
              <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-effyis-purple relative inline-block">
                <span className="relative text-white m-1 p-1	">commencer?</span>
              </span>{" "}
            </div>
            <p className="text-gray-700 text-center p-5 ">
              Le portail Lodin4Developers fournit des informations sur nos
              produits API. Pour voir comment cela fonctionne et explorer
              nos offres, cliquez sur le lien ci-dessous.{" "}
            </p>
          </div>
          <div className="px-2 4 pb-2 flex flex-col justify-start items-center">
            <Link to="/DeveloperIndexPage" className="rounded-lg px-4 py-2  bg-white  text-effyis-purple hover:bg-effyis-purple hover:text-white duration-300">
              Learn the basics
            </Link>
          </div>
        </div>

        <div className="rounded overflow-hidden shadow-xl  bg-white">
          <Light className="h-20 w-20 mr-auto ml-auto m-2 p-2" />
          <hr className="my-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
  <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 text-center">
              Developer{" "}
              <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-effyis-purple relative inline-block">
                <span className="relative text-white m-1 p-1">Blog</span>
              </span>
            </div>
            <p className="text-gray-700 text-center p-5 ">
              Découvrez nos idées, notre technologie et nos événements.
              Découvrez ce que d'autres ont construit et comment nos produits
              API peuvent répondre aux besoins de votre entreprise.{" "}
            </p>
          </div>
          <div className="px-2 flex flex-col justify-start items-center">
            <button className="rounded-lg px-4 py-2  bg-white  text-effyis-purple hover:bg-effyis-purple hover:text-white duration-300">
              Consultez notre blog
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
