import { Disclosure } from "@headlessui/react";
import { cx, Icon, ChevronUpIcon } from "@vechaiui/react";

const colorCheck = (type) => {
  if (type === "POST") {
    return "#49CC90";
  }
  if (type === "GET") {
    return "#61AFFE";
  }
  if (type === "PUT") {
    return "#FCA130";
  }
  if (type === "DELETE") {
    return "#FF0000";
  } else {
    return "#000000";
  }
};

const borderCheck = (type) => {
  if (type === "POST") {
    return 23;
  }
  if (type === "GET") {
    return 23;
  }
  if (type === "PUT") {
    return 23;
  }
  if (type === "DELETE") {
    return 23;
  } else {
    return 8;
  }
};

export default function DisclosureOne({ children, title, description, type }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={cx(
              "flex items-center justify-between bg-gray-200 w-full px-4 py-3 rounded cursor-base focus:outline-none",
              "bg-primary-50 text-primary-800 dark:bg-primary-200 dark:bg-opacity-15 dark:text-primary-200 mt-3"
            )}
          >
            <div className="flex-row flex w-full justify-start items-center">
              {type != null ? (
                <div className="lg:w-20 sm:w-16">
                  <p
                    style={{ backgroundColor: colorCheck(type) }}
                    className={`py-0.5 px-2 w-fit font-semibold  rounded-lg text-sm text-white mr-8 `}
                  >
                    {type}
                  </p>
                </div>
              ) : null}
              <div className="  w-full lg:flex lg:flex-row">
                <div
                  style={{
                    width:
                      type == "200" || type == "401" || type == "400"
                        ? "100%"
                        : "",
                  }}
                  className="flex self-start    "
                >
                  <span className=" break-all text-left font-bold">
                  {title}
                  </span>
                </div>

                <span className="lg:ml-10 flex text-left">{description}</span>
              </div>
            </div>

            <Icon
              as={ChevronUpIcon}
              label="chevron-up"
              className={cx(
                "w-4 h-4",
                open ? "transform rotate-180 text-primary-500" : ""
              )}
            />
          </Disclosure.Button>
          <Disclosure.Panel
            style={{
              flex: 1,
              borderColor: colorCheck(type),
              borderLeftWidth: borderCheck(type),
            }}
            className="lg:px-3 sm:px-1 pt-2 pb-2  h-max-screen pl-6  text-sm text-muted"
          >
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
