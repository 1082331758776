import React, { useEffect } from "react";
import {
  
  UserIcon,
  AnnotationIcon,
} from "@heroicons/react/outline";
import { CurrencyEuroIcon } from "@heroicons/react/outline";

import DropDown from "./DropDownModePaiement";

export default function WizardStep1({ setStep, setIdBankSelected, setIban, setAmount, setMotif, setTypePayment}) {
    

  
  useEffect(() => {
  }, []);

  return (
    <div
      className="px-4  justify-start items-center  overflow-auto    bg-white-almost pt-2 pb-2  "
      style={{
        width: "100%",
        maxHeight: window.innerHeight - 250,
      }}
    >
      {/* ================ ibna destinataire   ================ */}
      <div className="mb-3">
        <label
          className="font-bold ml-0.5 mb-2 text-effyis-purple block "
        >
          IBAN Destinaire
        </label>
        <div className="-mt-6">
          <UserIcon
            style={{ position: "relative", top: 37, left: 10 }}
            className="flex-shrink-0 h-6 w-6 text-violet-400 "
            aria-hidden="true"
          />
          <input
            type="text"
            className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
            placeholder="e.g. : FR1420041010050500013M02606"
            onChange={(event) => setIban(event.target.value)}
          />
        </div>
      </div>

      {/* ================ Montant   ================ */}
      <div className="mb-3">
        <label
          className="font-bold ml-0.5 mb-2 text-effyis-purple block "
        >
          Montant
        </label>
        <div className="-mt-6">
          <CurrencyEuroIcon
            style={{ position: "relative", top: 37, left: 10 }}
            className="flex-shrink-0 h-6 w-6 text-violet-400 "
            aria-hidden="true"
          />
          <input
            type="text"
            className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
            placeholder="e.g. : 890.50 €"
            onChange={(event) => setAmount(event.target.value)}

          />
        </div>
      </div>

      {/* ================ Type virement   ================ */}
      <DropDown setTypePayment={setTypePayment} />

      {/* ================ Motif   ================ */}
      <div className=" mt-3">
        <label
          className="font-bold ml-0.5 mb-2 text-effyis-purple block "
        >
          Motif
        </label>
        <div className="-mt-6">
          <AnnotationIcon
            style={{ position: "relative", top: 37, left: 10 }}
            className="flex-shrink-0 h-6 w-6 text-violet-400 "
            aria-hidden="true"
          />
          <input
            type="text"
            className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
            placeholder="e.g. : Remboursement Iphone X"
            onChange={(event)=> setMotif(event.target.value)}
          />
        </div>
      </div>



    </div>
  );
}
