import React, { useState, useEffect, useContext, useRef } from "react";
import { SetNav } from "./hooks/useNav";
import { NavContext } from "./context/NavContext";
import { ChevronRightIcon ,SearchIcon } from "@heroicons/react/outline";

const NavLink = ({ name, navId, scrollToId, index, subNavs }) => {
  const { activeNav, setActiveNav, activeNavLinkId, setActiveNavLinkId } =
    useContext(NavContext);
  useEffect(() => {}, [activeNav]);
  const handleClick = (navIdClicked, scrollToSubContent) => {
    console.log("you want to scrol to : " + scrollToId);
    console.log("active nablik id is : " + activeNavLinkId);
    setActiveNavLinkId(navIdClicked);
    setActiveNav(navIdClicked.split("-")[0]);

    // document.getElementById(scrollToSubContent).scrollIntoView({
    // 	behavior: 'smooth',
    //     block: 'center'
    // });
    const id = scrollToSubContent;
    const yOffset = -64;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
    // document.getElementById(scrollToSubContent).scrollIntoView();
  };
  const scrollToContent = (navIdClicked) => {
    const id = navIdClicked;
    const yOffset = -64;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  return (
    <div className="w-full flex flex-col justify-start items-center">
      <a
        key={index}
        id={navId}
        onClick={() => scrollToContent(subNavs[0].scrollToId)}
        style={{ backgroundColor: "#393939" }}
        className={
          activeNav == navId
            ? " w-full border-l-4 font-bold border-purple-800 text-white cursor-pointer inline-flex items-center  justify-start px-4 py-1 bg-purple-700  "
            : "duration-500 font-bold  w-full cursor-pointer text-white inline-flex items-center justify-start  px-4 py-1  "
        }
      >
        {name}
      </a>
      <div
        style={{
          backgroundColor: "#262626",
          transition: " cubic-bezier(0.3, 0.7, 0.3, 1) 1s ",
          maxHeight: activeNav == navId ? "200px" : "0px",
        }}
        className={
          `w-full   flex flex-col justify-center items-start cursor-pointer overflow-hidden `
          // activeNav == navId ? `hidden` : ``
        }
      >
        {
          //````````

          subNavs.map((sub, subindex) => (
            <a
              key={subindex}
              className={
                `w-full hover:font-semibold duration-300 text-white border-purple-800 cursor-pointer inline-flex items-center pl-7 justify-start px-4  py-1` +
                (activeNavLinkId === sub.navLinkId ? `    ` : ``)
                // activeNavLinkId === sub.navLinkId ?
                // " hover:text-purple-400 text-purple-800 font-bold border-purple-800   items-center  justify-start px-4 py-2.5  "
                // : "duration-500      items-center justify-start pl-5 px-4 py-2.5 "
              }
              style={{
                backgroundColor:
                  activeNavLinkId === sub.navLinkId ? "#4C1D95" : "",
              }}
              onClick={() => handleClick(sub.navLinkId, sub.scrollToId)}
            >
              {sub.name}
            </a>
          ))
        }
      </div>
    </div>
  );
};

export default function ScrollNavigation({ navLinks }) {
  const [isOpenNav, setIsOpenNav] = useState(window.innerWidth < 500 ? false : true);
  const [searchTyped, setSearchTyped] = useState("");
  const dimensions = () => {
    if(window.innerWidth < 500){
      return 50;
    }else if(window.innerWidth < 780){
      return 40;
    }else{
      return 18;
    }
  }
  const ConcatSubContents = () => {
    const { activeNav, setActiveNav, activeNavLinkId, setActiveNavLinkId } =
      useContext(NavContext);

    const handleClick = (navIdClicked, scrollToSubContent) => 
    {
      setActiveNavLinkId(navIdClicked);
      setActiveNav(navIdClicked.split("-")[0]);
      const id = scrollToSubContent;
      const yOffset = -64;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    };
    if (searchTyped.length > 2) {
      const subContents = navLinks
        .map(function (elem) {
          return elem.subContent;
        })
        .flat();
      console.log();
      const subContentsSearched = subContents.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchTyped.toLowerCase())
      );
      return (
        <div>
          {subContentsSearched.length == 0 ? (
            <p className="text-white font-thin">Pas de résultats</p>
          ) : (
            subContentsSearched.map((sub, subindex) => (
              <a
                key={subindex}
                className={`w-full  hover:underline duration-300 text-white border-purple-800 cursor-pointer inline-flex items-center pl-7 justify-start px-4  py-1`}
                style={{
                  backgroundColor: "",
                }}
                onClick={() => handleClick(sub.navLinkId, sub.scrollToId)}
              >
                {sub.name}
              </a>
            ))
          )}
        </div>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div className="flex flex-row " style={{  width: !isOpenNav ? (window.innerWidth < 500 ? '150%':'') : '' }}>
        
        <div
            
            className={
            `flex flex-col justify-start overflow-x-hidden items-center ` +
            (isOpenNav ? `  ` : `  `)
            }
            style={{
                zIndex:9,
                backgroundColor: "#393939",
            position: "sticky",
            top: 55,
            width: (dimensions()+'%'),
            height: window.innerHeight - 55,
            marginLeft: isOpenNav ? -dimensions()+"%" : 0,
            transition: " 0.2s ",
            }}
            
        >
            
            <SearchIcon
            style={{ position: "relative", top: 35, left: 18 }}
            className="-mt-4 self-start flex-shrink-0 h-5 w-5 text-gray-400 "
            aria-hidden="true"
            />
            <div className="flex  flex-row justify-center    my-2  py-1 border-b border-gray-500">
                <input
                    style={{ backgroundColor: "#393939", }}
                    className="pl-10 outline-0 text-white font-thin"
                    placeholder={"Rechercher"}
                    onChange={(event) => setSearchTyped(event.target.value)}
                />
            </div>

            <ConcatSubContents />   

            <div
                className="border-t border-gray-500 mt-2"
                style={{ width: "90%" }}
            />
            {navLinks.map((item, index) => (
            <NavLink
                index={index}
                navId={item.navId}
                scrollToId={item.scrollToId}
                name={item.name}
                subNavs={item.subContent}
            />
            ))}
        </div>
        
        <div className="flex flex-col justify-center items-center cursor-pointer"
            style={{
                paddingLeft:26,
                position: 'sticky',
                top:70,
                marginLeft:-30,
                width:50,
                borderRadius:15,
                height:50,
                marginRight:-20,
                zIndex:8,
                backgroundColor:'#262626',
            }}
            onClick={()=> setIsOpenNav(!isOpenNav)}
        >
            <ChevronRightIcon 
              style={{
                transition: " 0.2s ",
                transform: (isOpenNav ? '' : 'rotate(180deg)')
              }}
              className="w-5 text-white" 
            />

        </div>
                
        
        <div
            className="flex flex-col  bg-violet-700"
            style={{
              
            width: isOpenNav ? '100%' : (100-dimensions())+"%",
            // marginRight:-200
            // marginLeft: (isPhone ? '-50%' : 0)
            }}
        >   
            <div className="-mt-10 w-1/2 h-10 bg-black sticky top-24 self-end text-white flex flex-row justify-start pl-20 items-center">
              Example
            </div>
            {navLinks.map((item, index) => (
            <div key={index}>
                {item.content}
                {item.subContent.map((sub, subindex) => (
                <div key={subindex} ref={SetNav(sub.navLinkId)}>
                    {sub.content}
                </div>
                ))}
            </div>
            ))}
        </div>
    </div>
  );
}
