import React, { useState } from 'react';
import PasswordForm from './PasswordForm';
import {
    AnnotationIcon

  } from '@heroicons/react/outline'

import {
    FlagIcon,
    UserCircleIcon

} from '@heroicons/react/solid'
import DropDown from './DropDown';
import DropDownModePaiement from './DropDownModePaiement'
import WizardStep1 from './WizardStep1';
import WizardStep2 from './WizardStep2';
import WizardStep3 from './WizardStep3';
import WizardStep4 from './WizardStep4';
import WizardSuccess from './WizardSuccess';

export default function Wizard({setOpen}) {
    const [descriptionPaiement, setDescriptionPaiement] = useState('');
    const [descriptionRemboursement, setDescriptionRemboursement] = useState('');
    const [step, setStep] = useState(1);
    const [name, setName] = useState(1);
    const [idBankSelected, setIdBankSelected] = useState(-1);
    
	const [urlAuthBank, setUrlAuthBank] = useState("");
return (
    <div className="flex flex-col  justify-start  items-center h-full   pb-5  bg-white-almost ">
		<div style={{width:'100%'}} className=" max-w-3xl mx-auto  px-4 py-7">
			<div >
				{/* <!-- Top Navigation --> */}
				<div className="border-b-2 ">
					<div  className="" x-text="">
						<p className=' tracking-wide text-medium font-bold text-gray-500 mb-1 '>
							{`ETAPE: ${step!=3 ? step : 2 } sur 2`}
						</p>
                    </div>

					<div className="flex flex-col md:flex-row md:items-center md:justify-between">
						<div className="flex-1">
							<div style={{display: (step==1 ? 'block' : 'none')  }} >
								<div className="text-lg font-bold text-gray-700 leading-tight">Choisissez une banque</div>
							</div>
							
							<div style={{display:  (step==2 ? 'block' : 'none')  }}>
								<div className="text-lg font-bold text-gray-700 leading-tight">Connectez vous</div>
							</div>

							<div style={{display:  (step==3 ? 'block' : 'none')  }}>
								<div className="text-lg font-bold text-gray-700 leading-tight">Succés</div>
							</div>

							
						</div>

						<div className="flex items-center md:w-64">
							<div className="w-full bg-white rounded-full mr-2">
								<div 
                                    className="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white" 
                                    style={{width:  `${(step-1)*100/2}%`  } }
									// "'width: '+ parseInt(step / 3 * 100) +'%'"
                                >
                                </div>
							</div>
							<div className="text-sm font-bold w-10 text-gray-500" >{`${(step-1)*100/2}%`}</div>
						</div>
					</div>
				</div>
				{/* // <!-- /Top Navigation --> */}

				{/* ============ Wizard Steps content =========== */}
				<div className='justify-self-center self-center  py-10'>	
					{
						step==1 ? 
						<WizardStep1 setName={setName} setIdBankSelected={setIdBankSelected} setStep={setStep}/> : 
						<></>
					}
					
					{
						step==2 ? 
						<WizardStep2 setStep={setStep} setUrlAuthBank={setUrlAuthBank} idBankSelected={idBankSelected} /> : 
						<></>
					}

{
						step==3 ? 
						<WizardSuccess/> : 
						<></>
					}
					{/* <div style={{display:  (step==2 ? 'block' : 'none')  }}>
						<WizardStep2 setStep={setStep} idBankSelected={idBankSelected} />
					</div>

					<div style={{display:  (step==3 ? 'block' : 'none')  }}>
						<WizardSuccess/>
					</div> */}
					
				</div>
				
				
			</div>
		</div>

		{/* // <!-- Bottom Navigation -->	 */}
		<div className="fixed bottom-0 left-0 right-0 py-5 bg-white shadow-md" >
			<div className="max-w-3xl mx-auto px-4">
				<div className="flex justify-between">
					<div className="w-1/2">
						<button
							onClick={()=>setStep(step-1)}
							style={{display :(step > 1 && step !=3 ? 'block' : 'none')}} 
							className="w-32 focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border" 
						>Previous</button>
					</div>

					<div className="w-1/2 text-right flex justify-end">
						{/* <button
							style={{display :(step < 2 ? 'block' : 'none')}} 
							onClick={()=>setStep(step+1)}
							className="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium" 
						>Next</button> */}

						<button
							style={{display :(step == 2 ? 'block' : 'none')}}
							onClick={()=>setStep(3)}
							className="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium" 
						>Complete</button>
						<button
							style={{display :(step ==3 ? 'block' : 'none')}}
							onClick={()=> window.document.location.reload() }
							className=" block justify-self-center self-center mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border" 
                    	>Retourner à l'acceuil</button>
						

					</div>
				</div>
			</div>
		</div>
	</div>
        
)
}

