
import ScrollNavigation from '../Components/Core/OnePageDoc/ScrollNavigation'
import NavProvider from '../Components/Core/OnePageDoc/context/NavContext'
import ReactJson from "react-json-view";

const navLinks = [
  {
     name:'PART 1',
     navId:'part1',
     subContent:[
         {
             name:'lundi',
             navLinkId:'part1-LinkSubContent1',
             scrollToId:'part1-Content1',
             content: (
                <div className="flex flex-row ">
                <div 
                    className='flex flex-col w-1/2 bg-yellow-300 p-10' 
                    id="part1-Content1"
                >
                    <h1 className='text-4xl  justify-self-center self-center mb-10'>
                        This Part 1 sub Content 2
                    </h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lor
                    </p>
                </div>
                <div style={{backgroundColor:"#393939"}} className='flex justify-center flex-col w-1/2 text-white '>
                    <ReactJson
                        theme="threezerotwofour"
                        displayDataTypes={false}
                        name={false}
                        src={[
                            {
                              "bankResource": "string",
                              "bicfi": "string",
                              "name": "string",
                              "usage": "string",
                              "cashAccountType": "string",
                              "currency": "string",
                              "iban": "string",
                              "balanceType": "string",
                              "balanceAmount": "string",
                              "idUser": 0,
                              "resource_id": 0
                            }
                          ]}
                        iconStyle="square"
                        indentWidth={8}
                    />
                </div>
                </div>
             )
         },
         {
             name:'mardi',
             navLinkId:'part1-LinkSubContent2',
             scrollToId:'part1-Content2',
             content: (
                <div className="flex flex-row ">
                <div 
                    className='flex flex-col w-1/2 bg-orange-300 p-10' 
                    id="part1-Content2"
                >
                    <h1 className='text-4xl  justify-self-center self-center mb-10'>
                        This Part 1 sub Content 2
                    </h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lor
                    </p>
                </div>
                <div style={{backgroundColor:"#393939"}} className='flex justify-center flex-col w-1/2 text-white '>
                    <ReactJson
                        theme="threezerotwofour"
                        displayDataTypes={false}
                        name={false}
                        src={[
                            {
                              "bankResource": "string",
                              "bicfi": "string",
                              "name": "string",
                              "usage": "string",
                              "cashAccountType": "string",
                              "currency": "string",
                              "iban": "string",
                              "balanceType": "string",
                              "balanceAmount": "string",
                              "idUser": 0,
                              "resource_id": 0
                            }
                          ]}
                        iconStyle="square"
                        indentWidth={8}
                    />
                </div>
                </div>
             )
         },

     ]
  },
  {
     name:'PART 2',
     navId:'part2',
     subContent:[
         {
             name:'mercredi',
             navLinkId:'part2-LinkSubContent1',
             scrollToId:'part2-Content1',
             content: (
                <div className="flex flex-row ">
                <div 
                    className='flex flex-col w-1/2 bg-violet-300 p-10' 
                    id="part2-Content1"
                >
                    <h1 className='text-4xl  justify-self-center self-center mb-10'>
                        This Part 1 sub Content 2
                    </h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                        Lor
                    </p>
                </div>
                <div style={{backgroundColor:"#393939"}} className='flex justify-center flex-col w-1/2 text-white '>
                    <ReactJson
                        theme="threezerotwofour"
                        displayDataTypes={false}
                        name={false}
                        src={[
                            {
                              "bankResource": "string",
                              "bicfi": "string",
                              "name": "string",
                              "usage": "string",
                              "cashAccountType": "string",
                              "currency": "string",
                              "iban": "string",
                              "balanceType": "string",
                              "balanceAmount": "string",
                              "idUser": 0,
                              "resource_id": 0
                            }
                          ]}
                        iconStyle="square"
                        indentWidth={8}
                    />
                </div>
                </div>
             ),
             
         },
         {
             name:'Jeudi dimanche',
             navLinkId:'part2-LinkSubContent2',
             scrollToId:'part2-Content2',
             content: (
                <div className="flex flex-row ">
                    <div 
                        className='flex flex-col w-1/2 bg-blue-300 p-10' 
                        id="part2-Content2"
                    >
                        <h1 className='text-4xl  justify-self-center self-center mb-10'>
                            This Part 1 sub Content 2
                        </h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse voluptates, enim modi cupiditate ad, non, corporis excepturi nobis totam adipisci voluptas! At quis nobis, eius doloremque nisi beatae accusamus vero.
                            Lor
                        </p>
                    </div>
                    <div style={{backgroundColor:"#393939"}} className='flex justify-center flex-col w-1/2 text-white '>
                        <ReactJson
                            theme="threezerotwofour"
                            displayDataTypes={false}
                            name={false}
                            src={[
                                {
                                  "bankResource": "string",
                                  "bicfi": "string",
                                  "name": "string",
                                  "usage": "string",
                                  "cashAccountType": "string",
                                  "currency": "string",
                                  "iban": "string",
                                  "balanceType": "string",
                                  "balanceAmount": "string",
                                  "idUser": 0,
                                  "resource_id": 0
                                }
                              ]}
                            iconStyle="square"
                            indentWidth={8}
                        />
                    </div>
                </div>
             )
         },

     ]
  },
]
export default function DocumentationPage() {
  window.scrollTo(0, 0)
  return (
    <div style={{}} className="">
        <NavProvider >
            <ScrollNavigation  navLinks={navLinks}/>
        </NavProvider>
    </div>
    
  );
}