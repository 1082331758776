import explicationImage from '../../../Assets/images/payment-gateway.png'

const list = [
  "Customer reaches the e-shop checkout page and fills in required details.",
  "E-shop contacts Payment Gateway. Customer is directed to Payment Gateway with signed query parameters.",
  "In Payment Gateway available payment methods are displayed, Customer selects one.",
  "Customer is directed to selected channel: bank link in the case of Lodin",
  "Customer makes payment.",
  "With successful payment Customer is redirected back to e-shop return-URL.",
  "In case of cancelling payment or on exception, Customer is redirected back to Payment Gateway",
  "Messages are sent to e-shop notification-URL (see notifications). Notifications are sent as GET/POST/email only if shop has specified the values.",
  "Transaction info is received by Lodin.",
  "Payout batch is made to Merchant bank account according to merchant payout frequency agreement; e.g. in case of bank-link payments payout will be done on next bank-day; in case of credit-card payment payout can take up to seven bank-days.",
  "Click on return to e-shop link directs Customer to e-shop cancel-URL",
]
const ListePoints = ({list}) =>{
  return(
    <div>
      {list.map((item, index) =>
        <li style={{listStyleType:'decimal'}} key={index}>{item}</li>
      )}
    </div>
    
  );
}
export default function GatewayDetails() {
  return (
    <div className='  py-3 px-5 bg-gray-100 m-4 shadow-md rounded border-2 border-purple-300'>
        <div className='flex flex-col justify-start, items-start'>
            {/* First Paragraphe */}
            <p>
            Payment Gateway is the easiest way to integrate your site with MK service. It offers full set of dialogs to commence payment outside the e-commerce site. The browser is redirected to our Gateway - hence it is also known as 'redirect method'
            Flow (payment method selection is outside of the shop):
            </p>
            <br />
            <p>
              Flow (payment method selection is outside of the shop) :
            </p>
            <img 
              className='self-center my-2' 
              src={explicationImage}
            />
            <ListePoints list={list} />
            <br />
            <p className='font-semibold'>
              Very little knowledge of our API is needed. You just need to process the payment notifications coming to the URL's designated in Shop's settings.
            </p>
        </div>
    </div>
  );
}