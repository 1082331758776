import React from "react";
import {
  Overview,
  Particles,
  Content,
  Footer,
} from "../Components/Core";
import CookieConsent from "react-cookie-consent";

// ===========================|| Open Banking Page ||=========================== //

const LandingPage = () => {
  window.scrollTo(0, 0);
  return (
    <div className="overflow-hidden">
      <Particles />
      <div className="h-10"></div>
      <Content />

      <Overview />

      <CookieConsent
        style={{ backgroundColor: "#D23565" }}
        onAccept={() => {
          alert("Thanks!");
        }}
        debug={false}
        enableDeclineButton
        declineButtonStyle={{backgroundColor:'white', color:'black'}}
        buttonStyle={{backgroundColor:'#D23565', color:'white'}}
        declineButtonText="Decline"
        onDecline={() => {
          alert("Thanks!");
        }}
      >
        <div className="font-thin">
          By clicking “Accept All Cookies”, you agree to the storing of cookies
          on your device to enhance site navigation, analyze site usage, and
          assist in our marketing efforts.
        </div>
      </CookieConsent>
      <Footer />
    </div>
  );
};

export default LandingPage;
