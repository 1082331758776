

export default function Article({backColor, children, title, properties, mb, tags}) {
    return (
        <div  
            style={{backgroundColor: backColor, marginBottom: mb}} 
            className="h-full block max-h-screen overflow-auto  border-l-8 border-gray-800 px-5 break-all  py-4 flex flex-col justify-start items-start "
        >
            <div className=' overflow-x-hidden  font-thin '>
                <p  className=" text-grayText font-bold text-2xl mb-2">
                        {title}
                </p>
                
                {children}
                {
                    (tags != null ?
                        <div className="">
                            <p  className=" text-grayText font-bold text-2xl mb-2 mt-3">
                                Tags
                            </p>
                            <div className='lg:flex lg:flex-row sm:grid sm:grid-cols-1 sm:place-items-center'>
                            {
                                
                                tags.map((item, index)=>
                                    <p 
                                        key={index}
                                        style={{backgroundColor: '#6B21A8'}} 
                                        className={`py-0.5 px-2 w-fit font-semibold rounded-lg text-sm text-white mr-3 sm:mb-2 lg:mb-5`}
                                    >
                                        {item}
                                    </p>
                                )
                            }
                                
                            </div>
                        </div>
                        
                        :
                        <>
                        </>
                    )
                }
                
            </div>
        </div>
    )
}
