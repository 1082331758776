import React, { useState } from 'react';
import {    
    LocationMarkerIcon,
  } from '@heroicons/react/outline'

import {
    FlagIcon,
    UserCircleIcon

} from '@heroicons/react/solid'
export default function WizardStep1() {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [pays, setPays] = useState('');
    
    
    
    
return (
    
    <div className="flex flex-col justify-start items-center h-full  pb-5  bg-white-almost ">
        <div style={{width:'100%'}}
            
        >
            
            {/* ================ Nom et prénom  ================ */}
            <div className='grid lg:gap-6 md:gap-6 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'>
                {/* ================ Nom   ================ */}
                <div className="mb-3">
                    <label htmlFor="firstname" className="font-bold ml-0.5 mb-2 text-effyis-purple block ">
                        Nom
                    </label>
                    <div className='-mt-6'>
                        <UserCircleIcon  
                            style={{position: 'relative', top: 37, left:10}}
                            className="flex-shrink-0 h-6 w-6 text-violet-400  " aria-hidden="true" />
                        <input type="text"
                        onChange={event => setNom(event.target.value)}
                        className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                        text-gray-600 font-medium "
                        placeholder="Nom.."
                        />
                    </div>
                </div>

                {/* ================ Prenom   ================ */}
                <div className="mb-3">
                    <label htmlFor="firstname" className="font-bold ml-0.5 mb-2 text-effyis-purple block ">
                        Prénom
                    </label>
                    <div className='-mt-6'>
                        <UserCircleIcon  
                            style={{position: 'relative', top: 37, left:10}}
                            className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                        <input type="text"
                        onChange={event => setPrenom(event.target.value)}
                        className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                        text-gray-600 font-medium "
                        placeholder="Prénom.."
                        />
                    </div>
                </div>
           </div>



            

            
            

            {/* ================ Adresse   ================ */}
            <div className="mb-3">
                <label htmlFor="firstname" className="font-bold ml-0.5 mb-2 text-effyis-purple block ">
                     Adresse
                </label>
                <div className='-mt-6'>
                    <LocationMarkerIcon  
                        style={{position: 'relative', top: 37, left:12}}
                        className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                    <input type="text"
                    onChange={event => setAdresse(event.target.value)}
                    className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
                    placeholder="Adresse.."
                    />
                </div>
            </div>


            {/* ================ Pays   ================ */}
            <div className="mb-3">
                <label htmlFor="firstname" className="font-bold ml-0.5 mb-2 text-effyis-purple block ">
                    Pays
                </label>
                <div className='-mt-6'>
                    <FlagIcon  
                        style={{position: 'relative', top: 37, left:12}}
                        className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                    <input type="text"
                    onChange={event => setPays(event.target.value)}
                    className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
                    placeholder="Pays.."
                    />
                </div>
            </div>


        </div>
        
    </div>
)
}
