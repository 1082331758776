import React from "react";
import MagentoConfig from "../Assets/images/magento/magento-config.png";
import MagentoPay from "../Assets/images/magento/magento-pay.png";

const Magento = () => {

  return (
    <div className="">

        {/******  Header *****/}
        <div className="flex flex-col justify-start items-center p-6 bg-white-almost hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="text-gray-900 font-thin  text-4xl  tracking-wider p-6">Lodin plugin pour Magento 2</h5>
        </div>

        {/******  Body *****/}
        <div className="px-20 my-10">

            <h2 className="text-gray-900 font-thin text-2xl tracking-wider my-5"> Mode d'emploi </h2>
            <p> 
                Cet extension vous permet d'accepter les paiements en ligne dans Magento 2 en utilisant la Passerelle Effyis Payment.
                Ce module est Magento 2.3 et 2.4 compatible et disponible pour vous dans Magento Marketplace

            </p>
            <h2 className="text-gray-900 font-thin text-2xl tracking-wider my-5"> How to install</h2>

            <p> 
                L'installation est simple, vous suivez l'installation réguliere d'une extension Magento 2.
            </p>

            <h2 className="text-gray-900 font-thin text-2xl tracking-wider my-5"> Comment configurer les méthodes de paiements</h2>

            <img
                className="mx-auto my-5"
                src={MagentoConfig}
                alt="Magento config "
            /> 

            <h2 className="text-gray-900 font-thin text-2xl tracking-wider my-5"> Widget </h2>

            <img
                className="mx-auto my-5"
                src={MagentoPay}
                alt="Magento pay "
            /> 

        </div>
    </div>
)};

export default Magento;
