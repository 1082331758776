import React, { useState, useEffect } from "react";
import PasswordForm from "./PasswordForm";
import {
  AtSymbolIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  GlobeAltIcon,
  LibraryIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";

import { FlagIcon, UserCircleIcon } from "@heroicons/react/solid";

import LoadingSquares from "../../Lottie/LoadingSquares";
import axios from "axios";
import { Spring } from "react-spring";
export default function WizardStep1({ idBankSelected, setStep }) {
  const [urlAuthBank, setUrlAuthBank] = useState("");
  const [loading, setLoading] = useState(true);
  const getBankUrl = async () => {
    console.log("id bank selected is : " + idBankSelected);
    const body = {
      redirectURl:
        window.location.href.split("#")[0] + "#/RedirectBankAuthPage",
    };
    await axios
      .post(
        `https://dev.effyispayment.com/switch-sandbox-service/Authorization/authorize/${idBankSelected}`,
        body
      )
      .then((res) => {
        console.log("res.data : " + JSON.stringify(res.data));
        console.log("going to open url : " + res.data.url);
        let popup = window.open(
          res.data.url,
          "Se connecter",
          "width=800,height=600"
        );
        window.addEventListener(
          "storage",
          function (e) {
            // alert("Key : " + e.key  + " Old Value : " + e.oldValue +" New Value : " + e.newValue)
            console.log("url of change " + e.url);
            const fullUrl = e.url.split("?");
            const secondHalfUrl = fullUrl[1].split("&");
            const accessTokenData = secondHalfUrl[0];
            const accessToken = accessTokenData.split("=")[1];
            const idBankData = secondHalfUrl[1];
            const idBank = idBankData.split("=")[1];

            const ids = JSON.parse(localStorage.getItem("idBank"));
            if (!ids.includes(idBank)) {
              ids.push(idBank);
              localStorage.setItem("idBank", JSON.stringify(ids));
            }

            const tokens = JSON.parse(localStorage.getItem("accessToken"));

            if (!tokens.includes(accessToken)) {
              tokens.push(accessToken);
              localStorage.setItem("accessToken", JSON.stringify(tokens));
            }

            console.log("id bank is : " + ids);
            console.log("accessToken is : " + tokens);
            setStep(3);
          }.bind(this)
        );
        // setStep(3)
        setUrlAuthBank(res.data.url);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setLoader(false)
      });
  };

  useEffect(() => {
    getBankUrl();
  }, []);

  return (
    <div className="flex flex-col justify-start items-center h-full  overflow-hidden  bg-white-almost ">
      <div className="flex flex-col justify-start items-center">
        <h1 className="font-thin text-lg">
          Veuillez se connecter à votre banque
        </h1>
        <div className="h-32 w-32">
          <LoadingSquares />
        </div>
        <h1 className="font-thin text-lg cursor-pointer">
          Récupération du lien d'authentification en cours..
        </h1>
      </div>
    </div>
  );
}
