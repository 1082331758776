import React from "react";
import { UserCircleIcon } from "@heroicons/react/solid";
// ===========================|| Open Banking Page ||=========================== //

import { SaveIcon } from "@heroicons/react/outline";

const SandboxHome = () => {
  const dimensions = () => {
    if (window.innerWidth < 500) {
      return 50;
    } else if (window.innerWidth < 780) {
      return 40;
    } else {
      return 18;
    }
  };
  return (
    <div
      style={{
        paddingLeft: dimensions() < 40 ? dimensions() + "%" : 0,
      }}
      className="w-full h-screen flex flex-col justify-start items-center py-4  "
    >
      <div className="py-6 white flex justify-center w-full ">
        <div className=" px-6 text-gray-600  w-full ">
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5">
            <div className="flex flex-col justify-start items-center lg:col-span-2 md:col-span-2 sm:col-span-1 border shadow border-gray-100 rounded-xl bg-gray-50  ">
              <div className="flex flex-col justify-start items-center p-3  w-full ">
                <div className="w-full flex flex-row justify-start items-start  ">
                  <div className="flex flex-col w-full ">
                    <h1 className="text-xl font-semibold w-full ">
                      
                    </h1>
                    <p>Casablanca, Maroc</p>
                    <p>11:01 AM (GTM)</p>
                  </div>
                  {false ? 
                  <></> :
                  <div className="w-2/4 h-full p-0 rounded-full" style={{background:"linear-gradient(90deg, rgba(90,78,158,1) 0%, rgba(175,124,255,1) 100%)"}}>
                    <UserCircleIcon 
                      className="text-purple-medium"
                    />
                  </div>
                  
                  }
                </div>
                <div className="flex flex-col justify-start items-start w-full">
                  <p className="font-thin">Complétude du profil : 70 %</p>

                  <div 
                    className="bg-gray-500 h-2 rounded-full w-full"
                    style={{
                    }}
                  />
                  <div 
                    className=" bottom-2 relative h-2 rounded-full"
                    style={{
                      background:"linear-gradient(90deg, rgba(90,78,158,1) 0%, rgba(175,124,255,1) 100%)",
                      width : '70%'
                    }}
                  />
                </div>

              </div>

              <div className="w-full border-t-2" />

              <div className="py-2 lg:px-4 sm:px-2 md:px-2 flex flex-row lg:gap-4 md:gap-2 sm:gap-2 justify-center w-full items-center text-center">
                <div className=" rounded text-sm  font-semibold  p-1.5 cursor-pointer hover:bg-gray-100 active:bg-gray-200">
                  Charger une image
                </div>
                <div className=" rounded text-sm  font-semibold  p-1.5 cursor-pointer hover:bg-gray-100 active:bg-gray-200">
                  Supprimer votre image
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3 justify-start items-center row-span-2 p-6 shadow border lg:col-span-3 md:col-span-3 sm:col-span-1 border-gray-100 rounded-xl bg-gray-50 text-center ">
              <div className="flex flex-col self-start justify-start items-start text-left font-thin">
                <h1 className="text-xl font-semibold">Profile</h1>
                <p>Les informations peuvent être modifiées</p>
              </div>
              <div className="border-t-2 w-full" />

              {/* prenom + nom */}
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 mt-3 w-full">
                {/* input container 1 */}
                <div className="relative col-span-1 h-10 border-2 rounded input-component mb-5 ">
                  <input
                    type="text"
                    className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                  />
                  <label
                    htmlFor="prenom"
                    className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                  >
                    Prénom *
                  </label>
                </div>

                <div className="relative col-span-1 h-10 border-2 rounded input-component mb-5 ">
                  <input
                    type="text"
                    className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                  />
                  <label
                    htmlFor="prenom"
                    className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                  >
                    Nom *
                  </label>
                </div>
              </div>
              {/*end : prenom + nom */}

              {/* email + phone number */}
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3  w-full">
                {/* input container 1 */}
                <div className="relative col-span-1 h-10 border-2 rounded input-component mb-5 ">
                  <input
                    type="text"
                    className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                  />
                  <label
                    htmlFor="prenom"
                    className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                  >
                    Email *
                  </label>
                </div>

                <div className="relative col-span-1 h-10 border-2 rounded input-component mb-5 ">
                  <input
                    type="text"
                    className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                  />
                  <label
                    htmlFor="prenom"
                    className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                  >
                    Téléphone
                  </label>
                </div>
              </div>
              {/*end : email + phone number */}

              {/* email + phone number */}
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3  w-full">
                {/* input container 1 */}
                <div className="relative col-span-1 h-10 border-2 rounded input-component mb-5 ">
                  <input
                    type="text"
                    className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                  />
                  <label
                    htmlFor="prenom"
                    className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                  >
                    Province *
                  </label>
                </div>

                <div className="relative col-span-1 h-10 border-2 rounded input-component mb-5 ">
                  <input
                    type="text"
                    className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                  />
                  <label
                    htmlFor="prenom"
                    className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                  >
                    Pays
                  </label>
                </div>
              </div>
              {/*end : email + phone number */}

              <div className="flex flex-row justify-start items-center  self-start hover:bg-violet-700 cursor-pointer bg-purple-medium   rounded px-3 py-2">
                <SaveIcon color={"white"} className="w-6" />
                <p
                  style={{ color: "white" }}
                  className="ml-3 text-sm font-semibold "
                >
                  Enregistrer
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SandboxHome;
