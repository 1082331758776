import EffyisLogoWhite from "../../../Assets/icons/Lodin.png";
import {Link} from 'react-router-dom'

export default function Footer() {
  return (
    <div className="w-full bg-gray-100 mt-10">
      <div className="w-full py-5 px-4 sm:px-6 text-gray-800 flex sm:flex-col lg:flex-row flex-wrap justify-between  ">
        
        <div className="  lg:w-1/4 px-14 border-r sm:hidden lg:block ">
          <div className="text-sm  uppercase text-effyis-purple font-bold">Menu</div>
          <ul className="flex flex-col justify-center">
            <li className="my-2">
              <Link to="/" className="hover:text-effyis-purple" href="/#">
                Home
              </Link>
            </li>

            <li className="my-2">
              <Link to="/DeveloperIndexPage" className="hover:text-effyis-purple" href="/#">
                Developers portal
              </Link>
            </li>

            <li className="my-2">
              <Link to="/SearchDocLandingPage" className="hover:text-effyis-purple" >
                Products
              </Link>
            </li>

            <li className="mt-2">
              <Link
                to="https://effyisgroup.com/"
                className="hover:text-effyis-purple"
              >
                Effyis Group
              </Link>
            </li>
          </ul>
        </div>

        <div className=" lg:w-2/4 sm:w-full sm:py-8 flex flex-col justify-center items-center  lg:border-r lg:border-b-0 sm:border-b text-center">
          <img src={EffyisLogoWhite} className="w-40 ml-auto mr-auto mb-4" />

          <h3 className="font-bold text-xl text-effyis-purple mb-4">
            Next-Gen payment with Lodin
          </h3>

          <p className="text-gray-500 text-sm ">
            Your everyday payment companion.
          </p>
        </div>

        <div className="sm:pt-5 lg:pt-0 flex flex-col text-center justify-center items-center md:w-3/12 sm:w-full sm:ml-auto sm:mr-auto sm:text-center ">
          <div className="text-sm uppercase text-effyis-purple font-bold">
            Contact Us
          </div>

          <ul>
            <li className="my-2">
              <a className="hover:text-effyis-purple" href="/#">
                140bis, Rue de Renes, 75006 Paris
              </a>
            </li>

            <li className="my-2">
              <a className="hover:text-effyis-purple" href="/#">
                Sales@Lodin.com
              </a>
            </li>
          </ul>
        </div>


      </div>

      <div className="flex py-3 m-auto text-gray-800 text-sm flex-col items-center border-t max-w-screen-xl">
        <div className="md:flex-auto md:flex-row-reverse  flex-row flex"></div>

        <div className="my-3">
          All Right Reserved by Effyis Group Copyright ©{" "}
          {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
}
