import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/outline";
// ===========================|| Open Banking Page ||=========================== //
import BankCard from "./BankCard";
import TableTransactions from "./TableTransactions";
import AddBank from "./AddBank";

import MakePayment from "./MakePayment";

const SandboxHome = () => {


  const [accessToken, setAccessToken] = useState([]);
  const [idBank, setIdBank] = useState([]);
  const [accountSelected, setAccountSelected] = useState(0);
  const [random, setRandom] = useState(0);

  const getAccountData = async (accessToken, idBankSelected) => {
    console.log("once i refresh im here ");
    var idBankGet = localStorage.getItem("idBank");
    var accessTokenGet = localStorage.getItem("accessToken");

    if (idBankGet == null) {
      localStorage.setItem("idBank", JSON.stringify([]));
    } else {
      var temp = JSON.parse(idBankGet);
      setIdBank(temp);
    }

    if (accessTokenGet == null) {
      localStorage.setItem("accessToken", JSON.stringify([]));
    } else {
      var temp = JSON.parse(accessTokenGet);
      setAccessToken(temp);
    }
  };

  useEffect(() => {
    // getBankData(idBank)
    getAccountData();
  }, [random]);
  const [openAddBank, setOpenAddBank] = useState(false);
  const [openMakePayment, setOpenMakePayment] = useState(false);
  const dimensions = () => {
    if (window.innerWidth < 500) {
      return 50;
    } else if (window.innerWidth < 780) {
      return 40;
    } else {
      return 18;
    }
  };
  return (
    <div
      style={{
        paddingLeft: dimensions() < 40 ? dimensions() + "%" : 0,
      }}
      className="w-full h-screen flex flex-col justify-start items-center py-4 px-2"
    >
      {openAddBank ? (
        <AddBank open={openAddBank} setOpen={setOpenAddBank} />
      ) : (
        <></>
      )}
      {openMakePayment ? (
        <MakePayment
          idBank={idBank[accountSelected]}
          open={openMakePayment}
          setOpen={setOpenMakePayment}
        />
      ) : (
        <></>
      )}
      <div
        style={{
          borderColor:
            "linear-gradient(90deg, rgba(26,13,73,1) 0%, rgba(134,10,161,1) 100%, rgba(0,212,255,1) 100%)",
        }}
        className="flex flex-row flex-wrap gap-5 justify-end items-center self-end "
      >
        <div
          className=" whitespace-nowrap outline outline-2 select-none cursor-pointer inline-flex items-center justify-center px-4 py-1.5 border border-transparent 
                        rounded-full shadow-lg active:shadow-sm text-base  font-thin text-violet-700 bg-violet-100  hover:bg-violet-200"
          onClick={() => setOpenAddBank(true)}
        >
          <PlusIcon color={"#4C1D95"} className="w-5  mr-2 font-bold" />
          Ajouter un compte bancaire
        </div>

        <a
          className=" whitespace-nowrap outline outline-2 select-none cursor-pointer inline-flex items-center justify-center px-4 py-1.5 border border-transparent 
                        rounded-full shadow-lg active:shadow-sm text-base mr-5 font-thin text-violet-700 bg-violet-100  hover:bg-violet-200"
          onClick={() => setOpenMakePayment(true)}
        >
          Effectuer un paiement
        </a>
      </div>

      <div
        style={{
          width: "90%",
          flexFlow: "row",
        }}
        className="gap-5 overflow-x-scroll break-normal text-white h-48 mt-6 flex flex-row justify-start items-center px-6"
      >
        {accessToken.map((item, index) => (
          <div
            key={index}
            style={{
              borderRadius: 20,
              borderWidth: 5,
              borderColor: accountSelected == index ? "#d1d1d1" : "white",
            }}
            onClick={() => {
              setAccountSelected(index);
            }}
          >
            <BankCard accessToken={accessToken[index]} idBank={idBank[index]} />
          </div>
        ))}
      </div>

      <div className="w-full px-10 pt-10 h-40">
        <TableTransactions
          accessToken={accessToken[accountSelected]}
          idBank={idBank[accountSelected]}
        />
      </div>
    </div>
  );
};

export default SandboxHome;
