import React, { useState, useEffect } from "react";
import axios from "axios";
// ===========================|| Open Banking Page ||=========================== //
import LoadingDotsLottie from "../Lottie/LoadingDotsLottie";
const BankCard = ({ accessToken, idBank }) => {
  const [account, setAccount] = useState({});
  const [bank, setBank] = useState({});
  const getAccountData = async () => {
    const options = {
      headers: {
        access_token: accessToken,
        TPP_ID_BANK: idBank,
      },
    };

    await axios
      .get(
        `https://dev.effyispayment.com/switch-sandbox-service/AISP/accountInformation`,
        options
      )
      .then((res) => {
        console.log("table from account info :" + res.data);
        setAccount(res.data[0]);
      })
      .catch((error) => {
        localStorage.setItem("idBank", JSON.stringify([]));
        localStorage.setItem("accessToken", JSON.stringify([]));
        console.log("error in bank card : " + error);
        window.document.location.reload();
      });
  };

  const getBankData = () => {
    axios
      .get(
        `https://dev.effyispayment.com/switch-sandbox-service/banks/${idBank}`
      )
      .then((res) => {
        setBank(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAccountData();
    getBankData();
  }, []);
  return (
    <>
      {Object.keys(account).length === 0 ? (
        <div className="w-40 h-20">
          <LoadingDotsLottie />
        </div>
      ) : (
        <div
          style={{
            borderRadius: 15,
            background:
              "linear-gradient(90deg, rgba(90,78,158,1) 0%, rgba(76,29,149,1) 100%)",
            // boxShadow: "5px 5px 10px #B5B0D3,-5px -5px 10px #9189BE",
          }}
          className="cursor-pointer w-72 min-w-72  flex flex-col p-4 justify-start items-center h-40 shadow"
        >
          <div className="flex flex-row justify-start items-center w-full">
            <div className="mr-5">
              <img className="h-20 w-20 rounded-lg" src={bank.logoUrl} />
            </div>

            <div className="flex flex-col justify-center items-center">
              <div className="mt-1 text-left w-full">
                <p>{account.name}</p>
              </div>
              <div className="text-left w-full">
                <p>{account.iban}</p>
              </div>
            </div>
          </div>

          <div className="border-t w-full border-gray-300 mt-5"></div>
          <div className="mt-1 self-end justify-self-end">
            {account.balanceInformationDTO.balanceAmount} {" €"}
          </div>
        </div>
      )}
    </>
  );
};

export default BankCard;
