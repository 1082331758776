import React, { useState } from "react";

import { useSpring, animated } from "react-spring";
import FilterToggleMenu from "../Components/Core/Filter/FilterToggleMenu";

import Multiselect from "../Components/Core/DropDownMultiChoice/Multiselect";
import ProductContainer from "../Components/Core/Containers/ProductContainer";

import {
  SearchIcon

} from '@heroicons/react/outline'
// ===========================|| Search Documentation Page ||=========================== //

const SearchDocLandingPage = () => {
  window.scrollTo(0, 0);
  const [isUp, setUp] = useState(false);
  const toggle = () => setUp((state) => !state);
  const [showSidebar, setShowSidebar] = useState(false);
  const [tagsSelected, updateTagsSelected] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');

  const { xyz } = useSpring({
    xyz: isUp ? [-77, 0, 0] : [150, 0, 0],
    config: {
      // using duration instead of spring physics
      duration: 1000,
    },
  });
  React.useEffect(() => {
    toggle();
  }, []);
  return (
    <div className="lg:px-14 sm:px-8 overflow-x-hidden">
      <div>
        <p className="lg:text-6xl sm:text-left lg:text-left  md:text-5xl sm:text-5xl font-black pt-6 ">Rechercher</p>
      </div>

      <animated.div
        style={{
          transform: xyz.to((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
        }}
      >
        <div className="ml-20 w-10 h-2 bg-black" />
      </animated.div>

      {/*
          Search Input Component
          */}
      <div className="lg:pt-8 sm:pt-0">
        {/*
          Filter Component
          */}
        <div className="xl:px-0  ">
          <div className="flex sm:flex-col  sm:justify-center sm:items-center lg:flex-row md:flex-row  lg:justify-between md:justify-between md:items-end lg:items-end    ">

            {/* Filter */}
            <div className="mt-4 lg:mt-0 w-full">
              <div className="flex flex-nowrap flex  justify-start mt-4 mb-4">
                <div className="group block flex flex-nowrap   ">
                  {/* <Multiselect title={"Type"} list={["item 1", "item 2"]}/> */}
                </div>

                <div  className="sm:w-full md:w-auto lg:w-auto group block flex flex-nowrap  ">
                  <Multiselect
                    title={"Etat"}
                    tagsSelected={tagsSelected}
                    updateTagsSelected={updateTagsSelected}
                    listEtats={["Publié", "Déprécié"]}
                    listTypes={["Produit"]}
                  />
                </div>
              </div>
            </div>
            {/* Search */}
            <div className="flex mb-5 sm:w-full lg:w-auto md:w-auto">
              <div className="pt-2 w-full px-  relative text-gray-600">
                
                <div className='-mt-6 sm:w-full lg:w-auto md:w-auto'>
                    <SearchIcon  
                        style={{position: 'relative', top: 35, left:10}}
                        className="flex-shrink-0 h-6 w-6 text-gray-400 " aria-hidden="true" />
                    <input type="text"
                    
                    className=" sm:w-full lg:w-auto md:w-auto pl-12 px-4 py-2.5 rounded-lg  shadow focus:shadow-lg focus:outline outline  outline-gray-300 focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
                    placeholder="Nom, version ..."
                    onChange={(event) => setSearchedWord(event.target.value)}
                    />
                </div>
                
              </div>
            </div>

          </div>
        </div>

        <>
          <div
            className={`top-10 right-0 w-[35vw]   p-10 pl-20 text-white fixed h-full z-40 ${
              showSidebar ? "translate-x-0 " : "translate-x-full"
            }`}
          >
            {showSidebar ? (
              <button
                className="flex text-4xl text-black items-center cursor-pointer fixed right-10 top-16 z-50 "
                onClick={() => setShowSidebar(!showSidebar)}
              >
                x
              </button>
            ) : (
              <svg
                onClick={() => setShowSidebar(!showSidebar)}
                className="fixed  z-30 flex items-center cursor-pointer right-10 top-6"
                fill="#2563EB"
                viewBox="0 0 100 80"
                width="40"
                height="40"
              >
                <rect width="100" height="10"></rect>
                <rect y="30" width="100" height="10"></rect>
                <rect y="60" width="100" height="10"></rect>
              </svg>
            )}
            <FilterToggleMenu />
          </div>
        </>
        <div className="bg-gray-100 mb-16">
          <div role="article" className="bg-gray-100 py-4  ">
            {/* Header */}

            {/* API And products */}
            <div className="px-6 xl:px-0 ">
              <ProductContainer searchedWord={searchedWord} tagsSelected={tagsSelected} />
              {/*
          API Counter
          */}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchDocLandingPage;
