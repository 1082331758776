import ApiGreen from '../../../Assets/icons/api-green.png';
import ApiGray from '../../../Assets/icons/api-gray.png';
import ApiBlack from '../../../Assets/icons/api-black.png';



export default function Overview({color, version, running, title, pageColor}) {
return (
    
    <div className="flex flex-row justify-start  items-center lg:mr-0 sm:-mr-10">
        {/* Api logo */}
        <div style={{backgroundColor: color}} className={`rounded  `} >
            <img  className="h-20 " src={ApiGray} /> 
        </div>

        <div className="flex flex-col h-full items-start pl-4">
            {/* Api + version + running */}
            <div className="flex flex-row justify-start items-center">
                <p style={{backgroundColor: color}} className={`px-1 rounded text-sm text-white mr-3 `}>
                    API
                </p>
                <p className={`px-1 rounded text-sm text-white bg-gray-500  mr-3 `}>
                    {version}
                </p>
                <p className={`px-1 rounded text-sm text-white bg-gray-500 mr-3 `}>
                    {running ? ('running') : ('not running')}
                </p>
            </div>
            {/* Api title */}
            <div className='flex flex-col h-full items-start  '>
                <p className='text-gray-800  font-bold lg:text-4xl sm:text-3xl'>
                    {title}
                </p>
                
                <div style={{backgroundColor: pageColor}} className={`w-32 h-2  self-end justify-self-end relative top-2 lg:left-20  sm:-left-16`}>
                    
                </div>
            </div>
        </div>

        
    </div>
)
}



