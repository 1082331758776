import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";

// ===========================|| Open Banking Page ||=========================== //

const FixedSidebar = ({children}) => {
  window.scrollTo(0, 0);
  const [isOpenNav, setIsOpenNav] = useState(
    window.innerWidth < 500 ? false : true
  );

  const dimensions = () => {
    if (window.innerWidth < 500) {
      return 50;
    } else if (window.innerWidth < 780) {
      return 40;
    } else {
      return 18;
    }
  };
  return (
    
      <div 
        style={{
          
          position: "fixed",
          top: 55,
          width: dimensions() + "%",
          height: window.innerHeight - 55,
          marginLeft: (-dimensions() + "%"),
          left: isOpenNav ?  dimensions()+'%' : 0,
          transition: " 0.2s ",
          borderRightWidth: 2,
          paddingTop: 20,
        }}
        className="flex flex-row"
      >
        <div
          className={
            `flex flex-col justify-start overflow-x-hidden items-center ` +
            (isOpenNav ? `  ` : `  `)
          }
          style={{
            zIndex: 9,
            backgroundColor: "#FFF",
            // top: 55,
            width: '100%',
            height: '100%',
          }}
        >
          
          <div className="w-full h-full flex flex-col justify-start items-center px-4">
              {children}
          </div>
        </div>
        {/* ====================================== Side bar Button  */}

        <div
          className="flex self-start mt-6 jus flex-col justify-center items-center cursor-pointer lg:hidden "
          style={{
            paddingLeft: 26,
            // position: "fixed",
            top: 70,
            marginLeft: -30,
            width: 50,
            borderRadius: 15,
            height: 50,
            marginRight: -20 ,
            zIndex: 8,
            backgroundColor: "#262626",
          }}
          onClick={() => setIsOpenNav(!isOpenNav)}
        >
          <ChevronRightIcon
            style={{
              transition: " 0.2s ",
              transform: isOpenNav ? "" : "rotate(180deg)",
            }}
            className="w-5 text-white"
          />
        </div>
      </div>
      
  );
};

export default FixedSidebar;
