import React, { useState } from "react";
import woocommerce from '../../../Assets/images/woocommerce.jpeg'
import magento from '../../../Assets/images/magento.jpg'
import shopify from '../../../Assets/images/shopify-3.png'
import openCart from '../../../Assets/images/OpenCart_logo2.png'
import prestaShop from '../../../Assets/images/PrestaShop.png'
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import { BookOpenIcon, DownloadIcon, GlobeAltIcon } from "@heroicons/react/outline";

const ModuleCard = ({logo, name, url, pdfUrl}) => {
    

    return (
    <div
        to="/ModulePage"
        style={{
            boxShadow: 'inset 15.32px 15.32px 1px #B5B0D3, inset -15.32px -15.32px 1px #FFFFFF',
            borderRadius:"10%",
            padding:'15.32px',
        }}
    >
        <div style={{borderRadius:20 }} className="  flex flex-col    justify-start items-center">
            <div 
                style={{
                    backgroundColor:'#5A4E9E',
                    height:'50%',
                    width:'100%',
                    borderTopLeftRadius:20,
                    
                }}
                className="flex flex-col justify-start items-start pt-3 px-3 pb-3">
                <img 
                    src={logo} 
                    alt="development icon" 
                    style={{height:45, width:'auto'}}
                    className=""
                />
                <p className="text-2xl font-semibold mt-1 text-white">
                    {name}
                </p>
            </div>

            <div 
                style={{
                    backgroundColor:'white',
                    height:'60%',
                    width:'100%',
                    
                }}
                className="flex flex-col justify-center items-start px-2 gap-2 pt-3">
                    <div 
                        className="w-full gap-1 h-2/5 flex flex-row  items-center bg-purple-medium  text-white font-thin p-2"
                        style={{
                            borderRadius:'8px'
                        }}
                    >
                        <p className="flex flex-row justify-center items-center w-5/6 mr-2">
                        Guide utilisateur
                        </p>
                        <a href={pdfUrl} target='_blank' className="flex flex-row gap-1 hover:bg-purple-700 bg-violet-700 rounded p-1">
                            PDF
                            <BookOpenIcon width={20} />
                        </a>

                        <Link to={url} className="flex flex-row gap-1 hover:bg-purple-700 bg-violet-700 rounded p-1">
                            Web
                            <GlobeAltIcon width={20} />
                        </Link>
                    </div>

                    <Link 
                        to=""
                        className="w-full h-2/5 flex flex-row  items-center text-purple-medium hover:text-purple-700 bg-white font-thin p-2 hover:bg-gray-100"

                        style={{
                            // background:"white",
                            borderColor:"#5A4E9E",
                            borderWidth:2,
                            borderRadius:'8px',
                        }}
                    >
                        <p className="flex flex-row justify-center items-center w-5/6">
                        Télécharger le plugin
                        </p>
                        
                        
                        <DownloadIcon width={20} />
                    </Link>
            </div>
        </div>
    </div>
  );
};

export default ModuleCard;
