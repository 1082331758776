import React from "react";
import Transactions from "../Assets/icons/Bank-account-transaction-mapping-rules.png";
import GetAccess from "../Components/Core/Forms/GetAccess";

// ===========================|| Open Banking Page ||=========================== //

const OpenBankingPage = () => {
  window.scrollTo(0, 0)
  return (
    <div className="container mx-auto bg-gray-300 ">
      <h1 className="text-2xl py-2 font-bold tracking-tight text-gray-900  text-center ">
        Current APIs
      </h1>
      <div className="flex  -mx-5 overflow-hidden">
        <div className="flex flex-col my-5 px-5 w-full  overflow-hidden sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
          <div className="mt-14  bg-white border-solid border border-slate-400 divide-y divide-slate-200 flex-1 ">
            <div className="px-5 pb-5">
              <a >
                <h5 className="text-2xl py-2 font-bold tracking-tight text-gray-900 ">
                  API Merchant
                </h5>
              </a>

              <div className="flex justify-between items-center ">
                <span className="text-md font-normal text-gray-900 ">
                  Description
                </span>
              </div>
              <span className="text-md font-normal text-gray-900 ">
                More description.
              </span>
            </div>
            <div className="place-content-center flex flex-row ">
              <img
                className="w-60 h-40 p-4"
                src={Transactions}
                alt="transactions"
              />
              <img
                className="w-60 h-40 p-4"
                src={
                  "https://unigulf.sa/wp-content/uploads/2019/10/open-banking.png"
                }
                alt="s"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col my-5 px-5 w-full overflow-hidden sm:w-full md:w-full lg:w-1/2 xl:w-1/2  ">
          <div className="mt-14  bg-white border-solid border border-slate-400 divide-y divide-slate-200 flex-1">
            <div className="px-5 pb-5">
              <a >
                <h5 className="text-2xl py-2 font-bold tracking-tight text-gray-900">
                  API Request To Pay
                </h5>
              </a>

              <div className="flex justify-between items-center">
                <span className="text-md font-normal text-gray-900">
                  Description.
                </span>
              </div>
              <span className="text-md font-normal text-gray-900 ">
                More description.
              </span>
            </div>
            <div className="flex flex-row place-content-center mb ">
              <img className="w-60 h-40 p-4" src={Transactions} alt="pay" />
              <img
                className="w-60 h-40 p-4"
                src={Transactions}
                alt="banktransfert"
              />
            </div>
          </div>
        </div>
      </div>

      <GetAccess />
    </div>
  );
};

export default OpenBankingPage;
