import ApiGreen from "../../../Assets/icons/api-green.png";
import GeneralApi from "./GeneralApi";
import Article from "./Article";
import Properties from "./Properties";
import SecurityProperties from "./SecurityProperties";
import ListePuces from "./ListePuces";
import { Disclosure } from "@headlessui/react";
import { DownloadIcon } from "@heroicons/react/outline";
import DisclosureOne from "./DisclosureOne";
import ReactJson from "react-json-view";
import DisclosureEmpty from "../../Shared/DisclosureEmpty/DisclosureEmpty";
import { Link } from "react-router-dom";


const apis = [
  // =>>>>>>>>>>>>>>>>>>>>>>>>> API RTP

  // =>>>>>>>>>>>>>>>>>>>>>>>>> API RTP

  // =>>>>>>>>>>>>>>>>>>>>>>>>> API RTP

  {
    // general infos :

    apiGeneralData: {
      color: "#6B21A8",

      version: "1.0.0",

      running: true,

      title: "API PISP",

      pageColor: "#1F2937",

      description: <p>Cet Api permet de créer une requête de paiement .</p>,

      tags: ["bank-controller", "operation-controller"],

      link: "https://engin.effyispayment.com/switch-sandbox-service/",
    },
    collectionPath:require("../../../Assets/files/API_PISP.txt"),
    propertiesExample: [
      {
        name: "nom",

        value: "API PISP",
      },

      {
        name: "Postman",

        value: "v2.1.0",
      },

      {
        name: "consomme",

        value: "application/json",
      },

      {
        name: "produit",

        value: "application/json",
      },
    ],

    security: [
      {
        name: "oauth-2-legged",

        content: [
          {
            name: "type",

            value: "oauth2",
          },

          {
            name: "tokenUrl",

            value:
              "https://engin.effyispayment.com/ecom-sandbox-service/oauth/token",
          },

          {
            name: "flow",

            value: "application",
          },
        ],
      },
    ],

    operationsDetails: [
      // RTP create

      {
        type: "POST",

        title: "RTP/v1/RTP/",

        description: "Créer une requête de paiment",

        header: [
          {
            name: "EFFYIS_PAY_TPP_ID_BANK",

            type: "integer",

            need: "required",
          },
        ],

        body: {
          ibanRequester: "string",

          amount: "string",

          country: "string",

          remittanceInformation: "string",

          fullname: "string",

          paymentType: "string",
        },

        responses: [
          {
            code: 200,

            title: "Creation de la requête de paiement avec succés",

            description: "default response",

            exampleValue: { message: "string" },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec d'authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // get banks

      {
        type: "GET",

        title: "banks",

        description: "Récuperer la liste des banques compatible",

        header: null,

        body: null,

        responses: [
          {
            code: 200,

            title: "Récuperation la liste des banques avec succés",

            description: "default response",

            exampleValue: [
              {
                id: 0,

                bankName: "string",

                shortName: "string",

                longName: "string",

                logoUrl: "string",

                tokenUrl: "string",

                baseAccountsUrl: "string",

                state: "string",

                flow: "string",

                accountScope: "string",

                paymentScope: "string",

                authorizeUrl: "string",

                basePaymentsUrl: "string",

                endpointAccounts: "string",

                endpointTransactions: "string",

                host: "string",

                endpointBalances: "string",

                endpointPayment: "string",

                addedParam: "string",
              },
            ],
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec d'authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },
    ],
  },

  //  =========================================================================> Marchand API

  //  =========================================================================> Marchand API

  //  =========================================================================> Marchand API

  {
    apiGeneralData: {
      color: "#6B21A8",

      version: "1.0.0",

      running: true,

      title: "API Marchand",

      pageColor: "#1F2937",

      description: (
        <p>
          Cet Api permet de créer une commande, ainsi de consulter les
          informations du marchand
        </p>
      ),

      tags: ["payment-controller", "marchant-controller"],

      link: "https://engin.effyispayment.com/ecom-sandbox-service/",
    },
    collectionPath:require("../../../Assets/files/API_Marchand.txt"),
    propertiesExample: [
      {
        name: "nom",

        value: "API Marchand",
      },

      {
        name: "Postman",

        value: "v2.1.0",
      },

      {
        name: "consomme",

        value: "application/json",
      },

      {
        name: "produit",

        value: "application/json",
      },
    ],

    security: [
      {
        name: "oauth-2-legged",

        content: [
          {
            name: "type",

            value: "oauth2",
          },

          {
            name: "tokenUrl",

            value:
              "https://engin.effyispayment.com/ecom-sandbox-service/oauth/token",
          },

          {
            name: "flow",

            value: "application",
          },
        ],
      },
    ],

    operationsDetails: [
      {
        type: "POST",

        title: "pay/order",

        description: "Permet de payer une commande",

        body: {
          amount: 0,

          currency: "string",

          successfulURL: "string",

          unsuccessfulURL: "string",

          productItemDTOList: [
            {
              itemName: "string",

              itemPrice: "string",
            },
          ],
        },

        responses: [
          {
            code: 200,

            title: "OK",

            description: "default response",

            exampleValue: {
              url: "string",
            },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      {
        type: "GET",

        title: "marchant",

        description: "Consulter les informations du marchand",

        header: null,

        body: null,

        responses: [
          {
            code: 200,

            title: "Récuperation des informations du marchand avec succés",

            description: "default response",

            exampleValue: {
              id: "string",

              marchantName: "string",

              adress: "string",

              clientId: "string",

              clientSecret: "string",

              country: "string",

              iban: "string",

              modePayment: "string",

              remittanceInformationPayment: "string",

              remittanceInformationRefund: "string",

              bankUserDTO: {
                id: 0,

                bankName: "string",

                shortName: "string",

                longName: "string",

                logoUrl: "string",
              },
            },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },
    ],
  },



   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
   //  =========================================================================> Auth API ecom
  {
    apiGeneralData: {
      color: "#6B21A8",

      version: "1.0.0",

      running: true,

      title: "API Authentification",

      pageColor: "#1F2937",

      description: (
        <p>
          Cet Api permet de créer une requête de paiement, ça permet aussi de
          recevoir la liste des banques compatible.
        </p>
      ),

      tags: ["AccessTokenDTO", "oauth-controller"],

      link: "https://engin.effyispayment.com/ecom-sandbox-service/",
    },
    collectionPath:require("../../../Assets/files/API_Authentification_Marchand.txt"),
    propertiesExample: [
      {
        name: "nom",

        value: "API Authentification",
      },

      {
        name: "Postman",

        value: "v2.1.0",
      },

      {
        name: "consomme",

        value: "application/json",
      },

      {
        name: "produit",

        value: "application/json",
      },
    ],

    security: [
      {
        name: "client-id",

        content: [
          {
            name: "type",

            value: "apiKey",
          },

          {
            name: "name",

            value: "client_id",
          },

          {
            name: "as",

            value: "query param",
          },
        ],
      },

      {
        name: "client-secret",

        content: [
          {
            name: "type",

            value: "secret",
          },

          {
            name: "name",

            value: "client_secret",
          },

          {
            name: "as",

            value: "query param",
          },
        ],
      },
    ],

    operationsDetails: [
      {
        type: "POST",

        title: "oauth/token",

        description: "Générer un access-token",

        query: [
          {
            name: "client_id",

            type: "string",

            need: "required",
          },

          {
            name: "client_secret",

            type: "string",

            need: "required",
          },

          {
            name: "grant_type",

            type: "'client_credentials'",

            need: "required",
          },
        ],

        responses: [
          {
            code: 200,

            title: "Acces-token généré avec succés",

            description: "default response",

            exampleValue: {
              access_token: "string",

              token_type: "string",

              expires_in: "integer($int32)",

              scope: "string",
            },
          },

          {
            code: 401,

            title: "Client non valide",

            description: "default response",

            exampleValue: {
              error: "invalid_client",

              error_description: "Bad client credentials",
            },
          },
        ],
      },
    ],
  },

  


  // ================================================================================> Api PISP old

  // ================================================================================> Api PISP old

  // ================================================================================> Api PISP old

  {
    apiGeneralData: {
      color: "#6B21A8",

      version: "1.0.0",

      running: true,

      title: "API PISP",

      pageColor: "#1F2937",

      description: (
        <p>
          Cet Api permet de payer une commande, ainsi de consulter les
          informations du marchand
        </p>
      ),

      tags: ["bank-controller", "rtp-controller"],

      link: "https://engin.effyispayment.com/switch-sandbox-service/",
    },

    propertiesExample: [
      {
        name: "nom",

        value: "API PISP",
      },

      {
        name: "Postman",

        value: "v2.1.0",
      },

      {
        name: "consomme",

        value: "application/json",
      },

      {
        name: "produit",

        value: "application/json",
      },
    ],

    security: [
      {
        name: "oauth-2-legged",

        content: [
          {
            name: "type",

            value: "oauth2",
          },

          {
            name: "tokenUrl",

            value:
              "https://engin.effyispayment.com/ecom-sandbox-service/oauth/token",
          },

          {
            name: "flow",

            value: "application",
          },
        ],
      },

      {
        name: "client-id",

        content: [
          {
            name: "type",

            value: "apiKey",
          },

          {
            name: "name",

            value: "Client-id",
          },

          {
            name: "in",

            value: "header",
          },
        ],
      },
    ],

    operationsDetails: [
      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /requestMoney/v1/executeRTP/{idOperation}/{idBank}

      {
        type: "GET",

        title: "requestMoney/v1/executeRTP/{idOperation}/{idBank}",

        description:
          "Permet de récuperer l'interface d'authentification du banque pour une RTP",

        path: [
          {
            name: "idOperation",

            type: "string",

            need: "required",
          },

          {
            name: "idBank",

            type: "integer",

            need: "required",
          },
        ],

        // body:{

        //     "amount": 0,

        //     "currency": "string",

        //     "successfulURL": "string",

        //     "unsuccessfulURL": "string",

        //     "productItemDTOList": [

        //       {

        //         "itemName": "string",

        //         "itemPrice": "string"

        //       }

        //     ]

        // },

        responses: [
          {
            code: 200,

            title: "Success",

            description: "default response",

            exampleValue: {
              url: "string",
            },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /requestMoney/v1/executeRTPScanToPay/{idOperation}/{idBank}

      {
        type: "POST",

        title: "requestMoney/v1/executeRTPScanToPay/{idOperation}/{idBank}",

        description:
          "Permet de parametrer les liens profonds de redirection de succes/echec pour le scan to pay",

        path: [
          {
            name: "idOperation",

            type: "string",

            need: "required",
          },

          {
            name: "idBank",

            type: "integer",

            need: "required",
          },
        ],

        body: {
          successfulURL: "string",

          unsuccessfulURL: "string",
        },

        responses: [
          {
            code: 200,

            title: "Success",

            description: "default response",

            exampleValue: {
              url: "string",
            },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Invalid credentials",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /operation/RTP/{idOperation}

      {
        type: "GET",

        title: "operation/RTP/{idOperation}",

        description: "Permet de récuperer les informations d'une RTP",

        path: [
          {
            name: "idOperation",

            type: "string",

            need: "required",
          },
        ],

        body: null,

        responses: [
          {
            code: 200,

            title: "Succés",

            description: "default response",

            exampleValue: {
              fullName: "string",

              ibanDestinataire: "string",

              amount: 0,

              currency: "string",

              motif: "string",

              date: "string",
            },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /operation/status/{operationStatusEnum}

      {
        type: "PUT",

        title: "operation/status/{operationStatusEnum}",

        description: "Mettre à jour l'état de la RTP",

        path: [
          {
            name: "operationStatusEnum ",

            type: "Failed ou Completed",

            need: "required",
          },

          {
            name: "id_operation",

            type: "string",

            need: "required",
          },
        ],

        body: null,

        responses: [
          // code 200

          {
            code: 200,

            title: "Succés",

            description: "default response",

            exampleValue: {
              message: "string",
            },
          },

          // code 400

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          // code 401

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /banks

      {
        type: "GET",

        title: "banks",

        description: "Permet de récuperer la liste des banques",

        // header:[

        //     {

        //         name:'idOperation',

        //         type:'string',

        //         need:'required',

        //     },

        // ],

        body: null,

        responses: [
          // code 200

          {
            code: 200,

            title: "Succés",

            description: "default response",

            exampleValue: [
              {
                id: 0,

                bankName: "string",

                shortName: "string",

                longName: "string",

                logoUrl: "string",

                tokenUrl: "string",

                baseAccountsUrl: "string",

                state: "string",

                flow: "string",

                accountScope: "string",

                paymentScope: "string",

                authorizeUrl: "string",

                basePaymentsUrl: "string",

                endpointAccounts: "string",

                endpointTransactions: "string",

                host: "string",

                endpointBalances: "string",

                endpointPayment: "string",

                addedParam: "string",
              },
            ],
          },

          // code 400

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          // code 401

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /RTP/v1/RTP/ : (RTP create)

      {
        type: "POST",

        title: "RTP/v1/RTP/",

        description: "Créer une requête de paiment",

        header: [
          {
            name: "EFFYIS_PAY_TPP_ID_BANK",

            type: "integer",

            need: "required",
          },
        ],

        body: {
          ibanRequester: "string",

          amount: "string",

          country: "string",

          remittanceInformation: "string",

          fullname: "string",

          paymentType: "string",
        },

        responses: [
          {
            code: 200,

            title: "Creation de la requête de paiement avec succés",

            description: "default response",

            exampleValue: { message: "string" },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec d'authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /recharge

      {
        type: "POST",

        title: "recharge",

        description: "Recharger le compte marchand",

        // header:[

        //     {

        //         name:'EFFYIS_PAY_TPP_ID_BANK',

        //         type:'integer',

        //         need:'required'

        //     },

        // ],

        body: {
          idMarchant: "string",

          amount: "integer",
        },

        responses: [
          {
            code: 200,

            title: "Succés",

            description: "default response",

            exampleValue: { message: true },
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec d'authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> /recharge/{idMarchant}

      {
        type: "GET",

        title: "recharge/{idMarchant}",

        description:
          "Permet de récuperer l'historique des recharges du marchand",

        path: [
          {
            name: "idMarchant",

            type: "string",

            need: "required",
          },
        ],

        body: null,

        responses: [
          {
            code: 200,

            title: "Succés",

            description: "default response",

            exampleValue: [
              {
                dateRecharge: "string",

                status: "string",

                amount: 0,
              },
            ],
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },
    ],
  },

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  //  =========================================================================> AISP

  {
    apiGeneralData: {
      color: "#6B21A8",

      version: "1.0.0",

      running: true,

      title: "API AISP",

      pageColor: "#1F2937",

      description: (
        <p>
          Cet Api permet de récuperer les informations sur les comptes bancaires
        </p>
      ),

      tags: [
        "persiste-transaction-controller",
        "persiste-balance-controller",
        "persiste-account-controller",
      ],

      link: "https://engin.effyispayment.com/switch-sandbox-service/",
    },
    collectionPath:require("../../../Assets/files/API_AISP.txt"),
    propertiesExample: [
      {
        name: "nom",

        value: "API AISP",
      },

      {
        name: "Postman",

        value: "v2.1.0",
      },

      {
        name: "consomme",

        value: "application/json",
      },

      {
        name: "produit",

        value: "application/json",
      },
    ],

    security: [
      {
        name: "oauth-2-legged",

        content: [
          {
            name: "type",

            value: "oauth2",
          },

          {
            name: "tokenUrl",

            value:
              "https://engin.effyispayment.com/ecom-sandbox-service/oauth/token",
          },

          {
            name: "flow",

            value: "application",
          },
        ],
      },
    ],

    operationsDetails: [
      // ==========================> /account/

      {
        type: "GET",

        title: "account/",

        description:
          "Récuperer les informations sur l'ensemble des comptes de l'utilisateur",

        // body:{

        //     "amount": 0,

        //     "currency": "string",

        //     "successfulURL": "string",

        //     "unsuccessfulURL": "string",

        //     "productItemDTOList": [

        //       {

        //         "itemName": "string",

        //         "itemPrice": "string"

        //       }

        //     ]

        // },

        responses: [
          {
            code: 200,

            title: "Succes",

            description: "default response",

            exampleValue: [
              {
                bankResource: "string",

                bicfi: "string",

                name: "string",

                usage: "string",

                cashAccountType: "string",

                currency: "string",

                iban: "string",

                balanceType: "string",

                balanceAmount: "string",

                idUser: 0,

                resource_id: 0,
              },
            ],
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 500,

            title: "Erreur serveur interne",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // ==========================> /transaction/{idResource}

      {
        type: "GET",

        title: "transactions/{idResource}",

        description:
          "Récuperer l'historique des transactions pour une ressource",

        path: [
          {
            name: "idResource",

            type: "integer",

            need: "required",
          },
        ],

        body: null,

        responses: [
          {
            code: 200,

            title: "Succes",

            description: "default response",

            exampleValue: [
              {
                bankResource: "string",

                currency: "string",

                amount: "string",

                creditDebitIndicator: "string",

                entryReference: "string",

                status: "string",

                transactionDate: "string",

                remittanceInformation: "string",

                resource_id: 0,
              },
            ],
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 500,

            title: "Erreur serveur interne",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },

      // ==========================> /balances/{idResource}

      {
        type: "GET",

        title: "balances/{resourceId}",

        description: "Consuler le solde d'une ressource",

        path: [
          {
            name: "idResource",

            type: "integer",

            need: "required",
          },
        ],

        body: null,

        responses: [
          {
            code: 200,

            title: "Succes",

            description: "default response",

            exampleValue: [
              {
                resource_id: 0,

                balanceType: "string",

                amount: "string",

                currency: "string",

                syncDate: "2022-03-25T10:52:03.632Z",

                active: true,
              },
            ],
          },

          {
            code: 400,

            title: "Payload de la requête invalide",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 401,

            title: "Echec authentification",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },

          {
            code: 500,

            title: "Erreur serveur interne",

            description: "default response",

            exampleValue: {
              timestamp: "string",

              status: 0,

              error: "string",

              message: "string",

              path: "string",
            },
          },
        ],
      },
    ],
  },


    //  =========================================================================> Auth API switch
  //  =========================================================================> Auth API switch
  //  =========================================================================> Auth API switch
  //  =========================================================================> Auth API switch
  //  =========================================================================> Auth API switch
  //  =========================================================================> Auth API switch
  //  =========================================================================> Auth API switch

  {
    apiGeneralData: {
      color: "#6B21A8",

      version: "1.0.0",

      running: true,

      title: "API Authentification",

      pageColor: "#1F2937",

      description: (
        <p>
          Cet Api permet de créer une requête de paiement, ça permet aussi de
          recevoir la liste des banques compatible.
        </p>
      ),

      tags: ["AccessTokenDTO", "oauth-controller"],

      link: "https://engin.effyispayment.com/switch-sandbox-service/",
    },
    collectionPath:require("../../../Assets/files/API_Authentification_Banque.txt"),
    propertiesExample: [
      {
        name: "nom",

        value: "API Authentification",
      },

      {
        name: "Postman",

        value: "v2.1.0",
      },

      {
        name: "consomme",

        value: "application/json",
      },

      {
        name: "produit",

        value: "application/json",
      },
    ],

    security: [
      {
        name: "client-id",

        content: [
          {
            name: "type",

            value: "apiKey",
          },

          {
            name: "name",

            value: "client_id",
          },

          {
            name: "as",

            value: "query param",
          },
        ],
      },

      {
        name: "client-secret",

        content: [
          {
            name: "type",

            value: "secret",
          },

          {
            name: "name",

            value: "client_secret",
          },

          {
            name: "as",

            value: "query param",
          },
        ],
      },
    ],

    operationsDetails: [
      {
        type: "POST",

        title: "oauth/token",

        description: "Générer un access-token",

        query: [
          {
            name: "client_id",

            type: "string",

            need: "required",
          },

          {
            name: "client_secret",

            type: "string",

            need: "required",
          },

          {
            name: "grant_type",

            type: "'client_credentials'",

            need: "required",
          },
        ],

        responses: [
          {
            code: 200,

            title: "Acces-token généré avec succés",

            description: "default response",

            exampleValue: {
              access_token: "string",

              token_type: "string",

              expires_in: "integer($int32)",

              scope: "string",
            },
          },

          {
            code: 401,

            title: "Client non valide",

            description: "default response",

            exampleValue: {
              error: "invalid_client",

              error_description: "Bad client credentials",
            },
          },
        ],
      },
    ],
  },
];

export default function Overview({ idApi }) {
  const ID = parseInt(idApi) - 1;
  
  return (
    <div className="flex flex-col sm:overflow-hidden  justify-start items-center w-full pb-5 h-full  bg-white lg:pt-20 pt-8 sm:px-4 lg:px-10">
      <div className="self-start lg:block">
        <GeneralApi
          color={apis[ID].apiGeneralData.color}
          version={apis[ID].apiGeneralData.version}
          running={apis[ID].apiGeneralData.running}
          title={apis[ID].apiGeneralData.title}
          pageColor={apis[ID].apiGeneralData.pageColor}
        />
      </div>

      <div className="grid gap-7 w-full justify-center sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3 items-start lg:mt-14 sm:mt-6 ">
        <div className=" max-h-96 sm:col-span-1 md:col-span-1 lg:col-span-2 ">
          <Article
            backColor="#EBEBEB"
            title="Description"
            tags={apis[ID].apiGeneralData.tags}
          >
            {apis[ID].apiGeneralData.description}
          </Article>

          <div className="mt-6">
            <Article backColor="#EBEBEB" title="Chemin">
              <p className="md:text-lg sm:text-xs font-mono bg-purple-200 p-1 px-3 rounded-md italic">
                {apis[ID].apiGeneralData.link}
              </p>
            </Article>
          </div>
        </div>

        <div className="">
          <Article backColor="#EBEBEB" title="Documentation" mb={28}></Article>

          <Article backColor="#EBEBEB" title="Properties" mb={28}>
            <div className="w-full md:ml-10 lg:ml-0 sm:ml-0 ">
              <SecurityProperties properties={apis[ID].propertiesExample} />
            </div>
            
          </Article>

          <div className="grid w-full lg:grid-cols-2 sm:grid-cols-1 gap-3">
            <a
              className=" inline-flex w-full items-center justify-center px-2 py-1.5 border border-black 

                                    rounded-full drop-shadow-lg font-medium text-blackTextDark bg-white 

                                    hover:bg-gray-100 active:drop-shadow duration-800 "
            >
              Contacter le support
            </a>

            <a
              // onClick={()=>downloadFile()}
              // to=""
              href={apis[ID].collectionPath}
              download={apis[ID].apiGeneralData.title + ".json"}
              // target='_blank'
              className=" inline-flex w-full items-center justify-center px-2 py-1.5 border border-black 

                                    rounded-full drop-shadow-lg font-medium text-blackTextDark bg-white 

                                    hover:bg-gray-100 active:drop-shadow duration-800"
            >
              <DownloadIcon className="w-5 ml-2 mr-2 " />
              Collection Postman
            </a>
          </div>
        </div>
      </div>

      {apis[ID].security != null ? (
        <div className=" w-full self-start text-grayText font-bold ">
          <h1 className="self-start text-grayText font-bold md:text-4xl mt-6 sm:text-2xl">
            Securité
          </h1>
          <div className={`w-full grid sm:grid-cols-1 gap-3  justify-self-center self-center items-start mt-5 `+(apis[ID].security.length > 1 ? "lg:grid-cols-2" :"lg:grid-cols-1")}>
            {apis[ID].security.map((item, index) => (
              <div key={index} className="">
                <p
                  style={{ backgroundColor: "#6B21A8" }}
                  className={`py-0.5 px-2 w-fit font-semibold rounded-lg text-sm text-white mr-3 mb-5`}
                >
                  {item.name}
                </p>

                <SecurityProperties properties={item.content} />
              </div>
            ))}
          </div>

          {apis[ID].apiGeneralData.title != "API Authentification" ? (
            <div className="lg:mt-5 sm:mt-3 font-normal">
              <h1>
                Pour savoir comment générer un acces-token vous pouvez utiliser
                :
                &nbsp;&nbsp;
                <Link
                    className="font-thin text-xl text-purple-800 underline"
                    to="/ApiDetailsPage/3"
                >
                    API Authentification
                </Link>
              </h1>
              
              
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <h1 className="self-start text-grayText font-bold md:text-4xl lg:mt-10 sm:mt-6 sm:text-2xl">
        Opérations
      </h1>

      <div className="flex  flex-col w-full lg:mt-6 sm:mt-0 mb-16">
        {apis[ID].operationsDetails.map((item, index) => (
          <DisclosureOne
            key={index}
            type={item.type}
            title={"/" + item.title}
            description={item.description}
          >
            <div className="mt-6">
              <Article backColor="#EBEBEB" title="Chemin">
                <p className="md:text-lg sm:text-xs font-mono bg-purple-200 p-1 px-3 rounded-md italic">
                  {apis[ID].apiGeneralData.link + item.title}
                </p>
              </Article>
            </div>

            {item.header != null ? (
              <div>
                <h1 className="text-grayText font-bold md:text-3xl mb-4 mt-4  sm:text-lg">
                  Header
                </h1>

                <div className="ml-4">
                  {item.header.map((sub, subindex) => (
                    <p className="flex flex-row text-grayText font-normal text-lg mb-4 mt-4  sm:text-xs ">
                      <p className="font-semibold sm:text-md">{sub.name}</p>
                      {sub.need == "required" ? (
                        <p className="font-bold md:text-xl  sm:text-xs text-red-700">
                          &nbsp;&nbsp;*&nbsp;&nbsp;
                        </p>
                      ) : (
                        <></>
                      )}
                      :&nbsp;
                      <p className="font-mono  sm:text-xs ">{sub.type}</p>
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {item.query != null ? (
              <div>
                <h1 className="text-grayText font-bold md:text-3xl mb-4 mt-4  sm:text-lg">
                  Query params
                </h1>

                <div className="ml-4">
                  {item.query.map((sub, subindex) => (
                    <p className="flex flex-row text-grayText font-normal text-lg mb-4 mt-4  sm:text-xs ">
                      <p className="font-semibold sm:text-md">{sub.name}</p>
                      {sub.need == "required" ? (
                        <p className="font-bold md:text-xl  sm:text-xs text-red-700">
                          &nbsp;&nbsp;*&nbsp;&nbsp;
                        </p>
                      ) : (
                        <></>
                      )}
                      :&nbsp;
                      <p className="font-mono  sm:text-xs ">{sub.type}</p>
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {item.path != null ? (
              <div>
                <h1 className="text-grayText font-bold md:text-3xl mb-4 mt-4  sm:text-lg">
                  Path
                </h1>

                <div className="ml-4">
                  {item.path.map((sub, subindex) => (
                    <p className="flex flex-row text-grayText font-normal text-lg mb-4 mt-4  sm:text-xs ">
                      <p className="font-semibold sm:text-md">{sub.name}</p>
                      {sub.need == "required" ? (
                        <p className="font-bold md:text-xl  sm:text-xs text-red-700">
                          &nbsp;&nbsp;*&nbsp;&nbsp;
                        </p>
                      ) : (
                        <></>
                      )}
                      :&nbsp;
                      <p className="font-mono  sm:text-xs ">{sub.type}</p>
                    </p>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}



            {item.body != null ? (
              <div>
                <h1 className="text-grayText font-bold text-3xl mb-4 mt-4">
                  Body
                </h1>

                <DisclosureEmpty title="Schema">
                  <ReactJson
                    theme="threezerotwofour"
                    displayDataTypes={false}
                    name={false}
                    src={item.body}
                    iconStyle="square"
                    indentWidth={8}
                  />
                </DisclosureEmpty>
              </div>
            ) : (
              <></>
            )}

            {item.responses != null ? (
              <div>
                <h1 className="text-grayText font-bold text-3xl mb-4 mt-4 sm:text-lg">
                  Responses
                </h1>

                {item.responses.map((sub, subindex) => (
                  <div>
                    <DisclosureOne
                      key={subindex}
                      type={sub.code}
                      title={sub.title}
                    >
                      <Article backColor="#EBEBEB" title="Description">
                        {sub.description}
                      </Article>

                      <h1 className="text-grayText font-bold text-2xl mb-2 mt-4">
                        Schema
                      </h1>

                      <ReactJson
                        theme="threezerotwofour"
                        displayDataTypes={false}
                        name={false}
                        src={sub.exampleValue}
                        iconStyle="square"
                        indentWidth={8}
                      />
                    </DisclosureOne>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </DisclosureOne>
        ))}
      </div>

      {/* <h1 className='self-start text-grayText font-bold text-4xl mt-10'>

            Definitions

        </h1>

        <div className='flex flex-col w-full mt-7'>

            

            <DisclosureEmpty title='Properties Table'>

                <ReactJson 

                    theme='threezerotwofour'

                    displayDataTypes={false}

                    name={false}

                    src={propertiesExample} 

                    iconStyle='square'

                    indentWidth={8}

                />

            </DisclosureEmpty>

            

        </div>





        <div className='flex flex-col w-full mt-2'>

            

            <DisclosureEmpty title='Liste puces 1'>

                <ReactJson 

                    theme='threezerotwofour'

                    displayDataTypes={false}

                    name={false}

                    src={listePucesExample1} 

                    iconStyle='square'

                    indentWidth={8}

                />

            </DisclosureEmpty>

            

        </div> */}
    </div>
  );
}

const listePucesExample1 = {
  description:
    "The payment-requests Api can be used to perform a domestic payment on the PSU's behalf. The API endpoints allow a PISP to:",

  points: [
    "Register an intent to create a payment request",

    "Subsequently confirm the payment request for processing",

    "Retrieve the status of an existing payment request",

    "Cancel an existing payment request",
  ],
};

const listePucesExample2 = {
  description: "For now below are the kind of transfer that are supported :",

  points: [
    "SEPA credit transfer",

    "Instant Payment",

    "Standing order",

    "International Transfer",
  ],
};
