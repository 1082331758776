import OverviewApi from '../Components/Core/DocApi/OverviewApi'
import {
  
  useParams
} from "react-router-dom";
const ApiDetailsPage = () => {
  window.scrollTo(0, 0)

  let {id} = useParams();
  
  return (
    <OverviewApi idApi={id}/>
  );
};

export default ApiDetailsPage;
