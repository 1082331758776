import React from "react";

import EffyisLogoBlue from "../../../Assets/images/Mockup.png";

// ===========================|| Open Banking Page ||=========================== //

const BuildWithUs = () => {
  return (
    <div className=" bg-white lg:mx-20 md:mx-10 sm:mx-4 mb-10  ">
      <h1 className="text-black font-extrabold  lg:text-4xl md:text-4xl sm:text-3xl  text-center sm:mt-6 lg:mt-5 lg:my-3 md:mt-5 md:my-3 sm:px-4 ">
        Pourquoi nous choisir?
      </h1>

      <div className="bg-black  m-2 h-0.5 w-40  ml-auto mr-auto lg:mb-10 " />
      <div className="flex lg:flex-row text-left justify-center items-center sm:flex-col mt-8 sm:px-3 lg:px-20 bg-effyis-purple rounded relative  ">
        <img
          className="sm:w-88 sm:-mt-2 sm:mb-6  md:w-72 lg:w-96 lg:-mr-32 lg:top-10 relative lg:right-28"
          src={EffyisLogoBlue}
          alt="Workflow"
        />

        <div>
          <h1 className="text-white font-thin md:text-2xl sm:pt-4  lg:pt-10 sm:text-2xl   ">
          Choisissez-nous pour construire l'avenir de votre entreprise. Avec nos produits API, vous pouvez automatiser vos processus, innover avec des solutions technologiques de pointe, et connecter votre entreprise à des millions de clients dans le monde entier.<br/> Nous vous offrons une expertise technologique de premier plan, une sécurité de niveau professionnel et une grande flexibilité pour répondre à tous vos besoins. Rejoignez-nous aujourd'hui pour prendre votre entreprise au niveau supérieur.

          </h1>
          
          <ul className="mt-7 pb-10 list-inside  md:text-2xl list-disc text-white font-thin sm:text-lg sm:text-left sm:px-1 ">
            <li>Produits API exclusifs</li>

            <li>Production comme environnement sandbox</li>

            <li>Architecture et sécurité de haut niveau</li>

            <li>Documentation complète</li>

            <li>Opportunités de partenariat</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BuildWithUs;
