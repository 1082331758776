import React from "react";
import ReactPlayer from 'react-player'
import Affichage from "../Assets/images/joomla/affichage.PNG";
import Paramètres from "../Assets/images/joomla/paramètres.PNG";
import CheckoutExample from "../Assets/images/joomla/checkoutex.PNG";
import Qr from "../Assets/images/joomla/qr.JPG";
import Bnc from "../Assets/images/joomla/banque.JPG";
import Demo from "../Assets/videos/demoJoomla.mp4";



const Joomla = () => {
return (
    <div>

    <div className="flex lg:flex-col md:flex-col justify-center items-center lg:p-14 md:p-14 sm:py-5 bg-effyis-purple">
        <h5 className="text-white font-thin tracking-wider lg:text-5xl md:text-4xl sm:text-xl lg:p-6 md:p-6 sm:p-6 sm:text-center">Integration du plugin EffyisPay pour Joomla</h5>
    </div>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p>Ce plugin permet d'ajouter notre solution de paiement EffyisPay à votre boutique Joomla.</p>
        <p className="mb-4">En choisissant EffyisPay comme moyen de paiement, vous offrez à vos clients la possibilité de payer par virement bancaire aisément et en toute sécurité. 
        Notre application porte cette expérience à un niveau supérieur : en quelques clics seulement, les paiements sécurisés de vos clients seront effectués en un clin d'œil.</p>
        <p className="mb-4"><span className="font-bold text-gray-900">NB:</span> Ce plugin nécessite Joomla 3.X.X et j2store. Il nest pas encore compatible avec Joomla 4. Dès que l'extension J2Store sera compatible avec Joomla 4, nous mettrons également à jour notre extension. </p>
    </div>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p className="mb-4">Voici une vidéo démonstrative sur le fonctionnement du plugin EffyisPay</p>
        <ReactPlayer url={Demo} controls width="100%" height={"100%"}/>
    </div>


    <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Installation</h2>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p className="mb-4">Ce plugin est disponible sur  <a className="text-effyis-purple hover:underline" href="#"> </a></p>
        <p>Après avoir l'installer, il faut accéder à l'interface j2store puis setup puis payment methods pour trover le plugin EffyisPay.</p>
        <img className="max-w-full h-auto my-5" src={Affichage} alt="Affichage" />
    </div>

    <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Configuration</h2>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p className="mb-4">Après l'activation du plugin, il va falloir se diriger vers la page des paramètres en appuyant sur le plugin pour pouvoir le configurer.</p>
        <p className="mb-4"> Afin d'activer ce plugin il faut se connecter à EffyisPay en remplissant les deux champs <span className="font-bold text-gray-900"> Merchant id </span> et <span className="font-bold text-gray-900">  Merchant secret </span> afin de s'authentifier. </p>
        <img className="max-w-full h-auto my-5" src={Paramètres} alt="Paramètres" />
        <p className="mb-4">Une fois le formulaire rempli le plugin s'activera et le bouton de paiement EffyisPay s'affichera dans l'interface du paiement parmi les choix des modes de paiement disponibles.</p>
        <img className="max-w-full h-auto my-5 w-4/7" src={CheckoutExample} alt="Page du panier" />
    </div>

    <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Paiement</h2>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p className="mb-4">En cliquant sur le bouton EffyisPay, vous allez être redirigé vers une interface contenant un ticket où les informations de la commande et les détails du commerçant sont affichés.</p>
        <img className="max-w-full h-auto my-5" src={Qr} alt="Paramètres" />
        <p className="mb-4">Après, il faut choisir la banque que vous souhaitez payer avec, choisir votre compte et terminer la procédure du paiement.</p>
        <img className="max-w-full h-auto my-5" src={Bnc} alt="Paramètres" />
    </div>

</div>
);
}

export default Joomla;