import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import LoadingSquares from '../Lottie/LoadingSquares'
export default function Loading({open}) {
    const setOpen=()=> {}
  const cancelButtonRef = useRef(null)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed -top-5 z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end   justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden  sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div 
              className="relative p-1 text-white font-thin text-center  inline-block align-middle  rounded-lg  overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg ">
                  <div className="w-32 h-32 ml-4">
                    <LoadingSquares />
                  </div>
                  En cours de traitement ..
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}