import React, { useEffect } from 'react';

import LoadingSquares from '../../Lottie/LoadingSquares'


export default function WizardStep1() {
    useEffect(() => {
    },[]);
    
    
return (
    
    <div className="flex flex-col justify-start items-center h-full  overflow-hidden  bg-white-almost ">
        <div className="flex flex-col justify-start items-center">
                <h1 className="font-thin text-lg">
                    Veuillez se connecter à votre banque
                </h1>
                <div onClick className="h-32 w-32">
                    <LoadingSquares />
                </div>
                <h1 
                    className="font-thin text-lg cursor-pointer">
                    En Attente de paiement..
                </h1>
        </div>
    </div>
)
}
