import { Disclosure } from "@headlessui/react";
import { cx, Icon, ChevronUpIcon } from "@vechaiui/react";



  
export default function DisclosureEmpty({children, title, description, type, color}) {
    return (
        <Disclosure >
            {({ open }) => (
                <>
                <Disclosure.Button
                    className={cx(
                    "flex items-center justify-between bg-gray-200 w-full px-4 py-3 rounded cursor-base focus:outline-none",
                    "bg-primary-50 text-primary-800 dark:bg-primary-200 dark:bg-opacity-15 dark:text-primary-200 mt-3"
                    )}
                >
                    <div className='flex-row flex justify-start items-center'>
                        
                        <div className='grid sm:grid-cols-1 md:grid-cols-1 lg:gap-10   lg:grid-cols-2'>
                            
                            <div className='flex self-start  '>
                                
                                <span className='ml-3 break-all  text-left text-lg font-bold'>
                                    {title}
                                </span>
                                
                            </div>
                            <span className='flex self-start'>
                                {description}
                            </span>
                        </div>
                    </div>
                    
                    
                    <Icon
                    as={ChevronUpIcon}
                    label="chevron-up"
                    className={cx(
                        "w-4 h-4",
                        open ? "transform rotate-180 text-primary-500" : ""
                    )}
                    />
                </Disclosure.Button>
                <Disclosure.Panel 
                    className="  h-max-screen   text-sm text-muted"
                >
                    {children}
                </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
  }
  