import React, { useState, useEffect } from "react";

import WizardStep1 from "./WizardStep1";
import WizardStep2 from "./WizardStep2";
import Loading from "../../Loading/Loading";
import axios from "axios";
export default function Wizard({ setOpen, idBank }) {
  
  const [step, setStep] = useState(1);
  const [idBankSelected, setIdBankSelected] = useState(-1);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({});
  const sendPayment = () => {
    setIsLoading(true);

    console.log(data);
    axios
      .post(
        `https://dev.effyispayment.com/switch-sandbox-service/RTP/v1/makePayment/${idBank}`,
        data
      )
      .then((res) => {
        setStep(2);
        let popup = window.open(
          res.data.url,
          "Se connecter",
          "width=800,height=600"
        );

        console.log("res from make payment " + res.data);
      })
      .catch((error) => {
        console.error(error);
      });
    setIsLoading(false);
  };
  const [iban, setIban] = useState("");
  const [amount, setAmount] = useState("");
  const [motif, setMotif] = useState("");
  const [typePayment, setTypePayment] = useState("");
  useEffect(() => {
    setData({
      amount: amount,
      ibanDestinataire: iban,
      currency: "EUR",
      successfulReportUrl:
        window.location.href.split("#")[0] +
        "#/RedirectBankAuthPage?result=completed",
      unsuccessfulReportUrl:
        window.location.href.split("#")[0] +
        "#/RedirectBankAuthPage?result=failed",
      messageType: typePayment,
      country: "France",
      remittanceInformation: motif,
    });
    console.log(data);
  }, [iban, amount, motif, typePayment]);
  return (
    <div className="flex flex-col  justify-start  items-center h-full     bg-white-almost ">
      {isLoading ? <Loading open={isLoading} /> : <></>}
      <div style={{ width: "100%" }} className=" max-w-3xl mx-auto  px-4 py-7">
        <div>
          {/* <!-- Top Navigation --> */}
          <div className="border-b-2 ">
            <div className="" x-text=""></div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-2">
              <div className="flex-1">
                <div style={{ display: step == 1 ? "block" : "none" }}>
                  <div className="text-lg font-bold text-gray-700 leading-tight">
                    Veuillez Remplir les informations du virement
                  </div>
                </div>

                <div style={{ display: step == 2 ? "block" : "none" }}>
                  <div className="text-lg font-bold text-gray-700 leading-tight">
                    Paiment en cours
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* // <!-- /Top Navigation --> */}
          {/* ============ Wizard Steps content =========== */}
          <div className="justify-self-center self-center  pt-5 pb-20">
            {step == 1 ? (
              <WizardStep1
                setIban={setIban}
                setAmount={setAmount}
                setTypePayment={setTypePayment}
                setMotif={setMotif}
                setIdBankSelected={setIdBankSelected}
                setStep={setStep}
              />
            ) : (
              <></>
            )}
            {step == 2 ? <WizardStep2 /> : <></>}
          </div>
        </div>
      </div>

      {/* // <!-- Bottom Navigation -->	 */}
      <div className="fixed bottom-0 left-0 right-0 py-5 bg-white shadow-md">
        <div className="max-w-3xl mx-auto px-4">
          <div className="flex justify-center">
            <div className="w-1/2 text-right flex justify-center">
              {/* <button
							style={{display :(step < 2 ? 'block' : 'none')}} 
							onClick={()=>setStep(step+1)}
							className="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium" 
						>Next</button> */}

              <button
                style={{ display: step == 1 ? "block" : "none" }}
                onClick={() => sendPayment()}
                className="focus:outline-none inline-block border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium"
              >
                Effectuer le virement
              </button>
              <button
                style={{ display: step == 2 ? "block" : "none" }}
                onClick={() => setOpen(false)}
                className=" block justify-self-center self-center mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border"
              >
                Retourner à l'acceuil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
