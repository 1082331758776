import explicationImage from '../../../Assets/images/payment-gateway.png'
import {
  AtSymbolIcon,
  CurrencyEuroIcon,
} from '@heroicons/react/outline'
import {useState, useEffect} from 'react';
import ProductAdder from './ProductsAdder';
import axios from 'axios'
export default function GatewayDetails() {
  const [productItemDTOList, updateProducts] = useState([{id:1, itemName:'', itemPrice:''}])
  const [successfulURL, setSuccessfulURL] = useState('')
  const [unsuccessfulURL, setUnsuccessfulURL] = useState('')
  const [clientId, setClientId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [amount, setAmount] = useState(0);
  const [postedObject, setPostedObject] = useState({})
  const [defaultMarchand, setDefaultMarchand] = useState(false)
  const defaultCredentials = () => {
    setDefaultMarchand(!defaultMarchand)
    setClientId("a5c61822-0b03-4df6-94ed-dd2ee97dbe26")
    setClientSecret("6be5938e-6965-4441-952f-dc4e75284ea3")
  }

  const setPosted = () => {
    var temp = {
      "amount": amount,
      "currency": "EUR",
      "successfulURL": successfulURL,
      "unsuccessfulURL": unsuccessfulURL,
      "productItemDTOList": productItemDTOList
    }
    
    setPostedObject(temp)
    console.log("the Object we are going to post is : " + JSON.stringify(postedObject))
  }
  
  const axiosSend = async () => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    
    let grant_type = 'client_credentials'

    let url =`https://engin.effyispayment.com/ecom-sandbox-service/oauth/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=${grant_type}`
    await axios.post(url)
      .then (response =>{
        console.log(response.data.access_token)
        getOrderUrl(response.data.access_token)
      })
      .catch(error =>{
        console.log("Error",error)
      })
  }
  const getOrderUrl = async (token) => {
    let accessToken = token
    let axiosConfig = {
      headers: {
        Authorization : `Bearer ${accessToken}`
      },
    }
    let url ="https://engin.effyispayment.com/ecom-sandbox-service/pay/order"
    await axios.post(
      
      url,
      {
        "amount": amount,
        "currency": "EUR",
        "successfulURL": (successfulURL.includes("http") ? successfulURL : null),
        "unsuccessfulURL": (successfulURL.includes("http") ? successfulURL : null),
        "productItemDTOList": productItemDTOList
      },
      axiosConfig
      ).then (response =>{
        console.log(response.data.url)
        window.open(response.data.url);
      })
      .catch(error =>{
        console.log("Error",error)
      })
  }
  useEffect(() => {
    console.log("somme amount is : " + amount)
}, [productItemDTOList, amount]);

  return (
    <div className=' overflow-auto  py-3 px-5 bg-gray-100 m-4 shadow-md rounded border-2 border-purple-300'>
        <div className='flex flex-col justify-start, items-start'>

            {/*  Marchand credentials  */}
            <label htmlFor="firstname" className="self-center font-bold ml-0.5  text-xl text-effyis-purple block ">
              Indentifiants marchand : 
            </label>

            {/* Client id input : */}
            <div className="mt-3 mb-3 w-full flex flex-col justify-center items-start">
                
                <label 
                  className="mb-1 font-bold font-mono ml-0.5 text-effyis-purple  "
                >
                    Client-id : 
                </label>
                
                <input type="text"
                  style={{width:'100%', color: (defaultMarchand ? "gray" : 'black')}}
                  className=" px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                  text-gray-600 font-medium "
                  placeholder={defaultMarchand ? "Marchand client-id par défaut" : 'e.g. : k5f69314-4b2a-5g2h-42ae-kk3ab94elk21'}
                  disabled={defaultMarchand}
                  value={clientId}
                  />
            </div>

            {/* Client secret input : */}
            <div className=" mb-3 w-full flex flex-col justify-center items-start">
                <label 
                  className="w-44 mb-1 font-bold font-mono ml-0.5 text-effyis-purple block "
                >
                    Client-Secret : 
                </label>
                <input type="text"
                  style={{width:'100%', color: (defaultMarchand ? "gray" : 'black')}}
                  className=" px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                  text-gray-600 font-medium "
                  disabled={defaultMarchand}
                  value={clientSecret}
                  placeholder={defaultMarchand ? "Marchand client-secret par défaut" : 'e.g. : k5f69314-4b2a-5g2h-42ae-kk3ab94elk21'}
                  />
            </div>
            <div className='w-full flex flex-col'>
              <p className='self-center'>
                Vous êtes pas encore un marchand ? 
                <span onClick={()=> defaultCredentials() } className='ml-2 font-bold text-violet-600 cursor-pointer '>
                  Utiliser l'identifiant du marchand test
                </span>
              </p>
              
            </div>


            {/*  Return urls   */}
            <label htmlFor="firstname" className="mt-5 self-center font-bold ml-0.5 text-xl text-effyis-purple block ">
              URLS de retour
            </label>

            {/* Successful input : */}
            <div className="mt-3 mb-3 w-full flex flex-col justify-center items-start">
                
                <label 
                  className="mb-1 font-bold font-mono ml-0.5 text-effyis-purple  "
                >
                    Successful URL : 
                </label>
                
                <input type="text"
                  style={{width:'100%'}}
                  className=" px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                  text-gray-600 font-medium "
                  placeholder="e.g. : https://myStore.com/Successful/"
                  />
            </div>

            {/* Unsuccessful  input : */}
            <div className=" mb-3 w-full flex flex-col justify-center items-start">
                <label 
                  className="w-44 mb-1 font-bold font-mono ml-0.5 text-effyis-purple block "
                >
                    Unsuccessful URL : 
                </label>
                <input type="text"
                  style={{width:'100%'}}
                  className=" px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                  text-gray-600 font-medium "
                  placeholder="e.g. : https://myStore.com/Failed/"
                  />
            </div>

            

            {/* PRoducts  */}
            <ProductAdder 
              products={productItemDTOList} 
              updateProducts={updateProducts}
              amount={amount}
              setAmount={setAmount}
            />
            

            <div
              onClick={() => axiosSend()}
              style={{width:'100%', height:47}}
              className="self-center tracking-widest justify-self-end mt-2 cursor-pointer rounded-md whitespace-nowrap inline-flex items-center justify-center px-4 py-1.5 border border-transparent 
                              shadow-sm text-xl font-thin text-white bg-violet-500 hover:bg-violet-600"
                              
            >
                Génerer la commande
            </div>

            



        </div>
    </div>
  );
}