const FiltreToggleMenu = () => {
  return (
    <div className="right-0 w-[35vw] bg-white-almost  p-10 pl-20 text-white fixed h-full ">
      <h2 className=" text-4xl font-semibold text-black -mt-3">
        Filter Component
      </h2>
    </div>
  );
};

export default FiltreToggleMenu;
