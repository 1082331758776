import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
// ===========================|| Open Banking Page ||=========================== //
import { SaveIcon } from "@heroicons/react/outline";

const SandboxSettings = () => {
  const dimensions = () => {
    if (window.innerWidth < 500) {
      return 50;
    } else if (window.innerWidth < 780) {
      return 40;
    } else {
      return 18;
    }
  };
  return (
    <div
      style={{
        paddingLeft: dimensions() < 40 ? dimensions() + "%" : 0,
      }}
      className="w-full h-screen flex flex-col justify-start items-center py-4  "
    >
      <div className="py-6 white flex justify-center w-full ">
        <div className=" px-6 text-gray-600  w-full ">
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5">

            
            {/* left div start */}
            <div className="flex flex-col gap-3 justify-start items-center row-span-2 p-6 shadow border lg:col-span-3 md:col-span-3 sm:col-span-1 border-gray-100 rounded-xl bg-gray-50 text-center ">
              <div className="flex flex-col self-start justify-start items-start text-left font-thin">
                <h1 className="text-xl font-semibold">Notifications</h1>
                <p>Gérer les notifications</p>
              </div>
              <div className="border-t-2 w-full" />
              
              <div className="grid grid-cols-2 w-full">
                
                <div className="flex flex-col gap-4 justify-start items-center w-full ">
                  <h1 className="text-lg self-start  text-left font-semibold">
                    Notifications
                  </h1>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Email
                    </p>
                  </div>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Push Notifications
                    </p>
                  </div>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Messages
                    </p>
                  </div>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Appels
                    </p>
                  </div>

                </div>

                <div className="flex flex-col gap-4 justify-start items-center w-full ">
                  <h1 className="text-lg self-start  text-left font-semibold">
                    Messages
                  </h1>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Email
                    </p>
                  </div>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Push Notifications
                    </p>
                  </div>

                  <div className="flex flex-row gap-3 justify-start items-start h-7 w-full  ">
                    <input
                      type="checkbox"
                      className="h-full w-6 px-2 transition-all border-blue  rounded-sm"
                    />
                    <p className="text-lg font-thin">
                      Appels
                    </p>
                  </div>

                </div>

                


              </div>
              
              {/* border */}
              <div className="border-t-2 w-full  -mx-2" /> 
              <div className="flex flex-row justify-start items-center  self-start hover:bg-violet-700 cursor-pointer bg-purple-medium   rounded px-3 py-2">
                <SaveIcon color={"white"} className="w-6" />
                <p
                  style={{ color: "white" }}
                  className="ml-3 text-sm font-semibold "
                >
                  Enregistrer
                </p>
              </div>
            </div>
            {/* left div end */}



            {/* right div start */}
            <div className="flex flex-col gap-3 justify-start items-center p-6 shadow border lg:col-span-2 md:col-span-2 sm:col-span-1 border-gray-100 rounded-xl bg-gray-50 text-center ">
              <div className="flex flex-col self-start justify-start items-start text-left font-thin">
                <h1 className="text-xl font-semibold">Mot de passe</h1>
                <p>Mettre à jour votre mot de passse</p>
              </div>
              <div className="border-t-2 w-full" />

              {/* prenom + nom */}
              {/* input container 1 */}
              <div className="relative mt-5 w-full h-10 border-2 rounded input-component mb-5 ">
                <input
                  type="password"
                  className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                />
                <label
                  htmlFor="prenom"
                  className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                >
                  Mot de passe *
                </label>
              </div>

              <div className="relative w-full h-10 border-2 rounded input-component mb-5 ">
                <input
                  type="password"
                  className="h-full w-full border-gray-300 px-2 transition-all border-blue rounded-sm"
                />
                <label
                  htmlFor="prenom"
                  className="absolute bottom-7 left-2 transition-all bg-gray-50 px-1 font-thin text-gray-500"
                >
                  Comfirmer le mot de passe
                </label>
              </div>
              {/*end : email + phone number */}

              {/* border */}
              <div className="border-t-2 w-full" /> 
              <div className="flex flex-row justify-start items-center  self-start hover:bg-violet-700 cursor-pointer bg-purple-medium   rounded px-3 py-2">
                
                <p
                  style={{ color: "white" }}
                  className="mr-3 text-sm font-semibold "
                >
                  Mettre à jour
                </p>
                <CheckIcon color={"white"} className="w-6" />
              </div>
            </div>
            {/* right div end */}



          </div>
        </div>
      </div>
    </div>
  );
};

export default SandboxSettings;
