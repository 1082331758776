
const RedirectBankAuthPage = () => {
  localStorage.setItem('account', Math.random() );
  window.close();
  return (
    <div>
      Vous allez être rederigé dans une instant ..
    </div>
  );
};

export default RedirectBankAuthPage;
