import React from "react";

// ===========================|| Open Banking Page ||=========================== //

const ApiCard = ({productDetails}) => {
  return (
 
    <div role="cell" className="bg-gray-100">
        <div className=" flex flex-col hover:-translate-y-2 mt-4">
          <a
            href='#'
            style={{
              backgroundColor:(productDetails.type === 'produit' ? (productDetails.productName === 'Produit Banque' ? '#4C1D95' : '#694D96') : '#A78BFA') //A78BFA
            }}
            className="group block max-w-xs rounded-top-lg p-6 bg-violet-400  ring-1 ring-slate-900/5 shadow-lg space-y-3   "
          >
            <div className="flex items-center space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
              </svg>
              <div className="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-violet-900 rounded-full">
                {productDetails.type}
              </div>
            </div>
            <p className="text-white md:text-white font-bold ">
                {productDetails.productName}
            </p>
            <div className="flex items-center ">
              {
                productDetails.state ===  'published' ?
                <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-green-400 text-white rounded-full">
                  publié
                </div>
                :
                <></>
              }
              
              {
                productDetails.state ===  'deprecated' ?
                <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-red-500 text-white rounded-full">
                  Déprécié
                </div>
                :
                <></>
              }

              <div className="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-violet-900 rounded-full">
                {productDetails.version}
              </div>
            </div>

          </a>
          <a
            href="#"
            className="group block max-w-xs rounded-bottom-lg p-6 bg-neutral-200 ring-1 ring-slate-900/5 shadow-lg space-y-3  h-12 min-h-full  "
          ></a>
        </div>
    </div>

  );
};

export default ApiCard;


