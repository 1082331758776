import React, { useState, useEffect } from "react";
import PasswordForm from "./PasswordForm";
import {
  AtSymbolIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  GlobeAltIcon,
  LibraryIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";

import { FlagIcon, UserCircleIcon } from "@heroicons/react/solid";
import axios from "axios";
export default function WizardStep1({ setStep, setIdBankSelected }) {
  const [banks, setBanks] = useState([]);
  const fetchBanks = async () => {
    await axios
      .get(`https://dev.effyispayment.com/switch-sandbox-service/banks`)
      .then((res) => {
        setBanks(res.data);
        // setLoader(false)
      })
      .catch((error) => {
        console.error(error);
        // setLoader(false)
      });
  };

  const bankPressed = (item) => {
    setStep(2);
    setIdBankSelected(item.id);
  };
  useEffect(() => {
    fetchBanks();
  }, []);

  return (
    <div
      className="grid grid-cols-3 px-4 gap-y-8 gap-x-4 justify-start items-center  overflow-auto  pb-5  bg-white-almost  "
      style={{
        width: "100%",
        maxHeight: window.innerHeight - 250,
      }}
    >
      {banks.map((item, index) => (
        <div
          style={{
            borderRadius: 20,
          }}
          className="flex flex-col w-full h-48 overflow-hidden bg-white border-violet-50 border-8 shadow-md active:shadow-none cursor-pointer"
          onClick={() => {
            bankPressed(item);
          }}
        >
          <div
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            className="flex flex-col  justify-center items-center  w-full h-3/5"
          >
            <img className="w-full h-48 " src={item.logoUrl} />
          </div>

          <div
            style={{}}
            className="flex flex-col justify-center items-center bg-purple-50 h-full w-full "
          >
            <h1 className="text-lg font-thin text-center">{item.bankName}</h1>
          </div>
        </div>
      ))}
    </div>
  );
}

const banks = [
  {
    id: 4,
    bankName: "Crédit Mutuel de Bretagne",
    shortName: "Crédit Mutuel de Bretagne",
    longName: "Crédit Mutuel de Bretagne",
    logoUrl: "https://i.ibb.co/nDB1gsd/Cr-dit-Mutuel-de-Bretagne.png",
  },
  {
    id: 5,
    bankName: "Crédit Mutuel du Sud-Ouest",
    shortName: "Crédit Mutuel du Sud-Ouest",
    longName: "Crédit Mutuel du Sud-Ouest",
    logoUrl: "https://i.ibb.co/59dZQX0/Mutuel-du-Sud-Ouest.png",
  },
  {
    id: 10,
    bankName: "BCP BANQUE",
    shortName: "BCP",
    longName: "BCP BANQUE",
    logoUrl: "https://i.ibb.co/NNpB8D4/Banque-BCP.png",
  },
  {
    id: 1,
    bankName: "BNP PARIS BAS",
    shortName: "BNP PARIS BAS",
    longName: "BNP PARIS BAS",
    logoUrl: "https://i.ibb.co/phtS5Rd/BNP-Paribas.png",
  },
  {
    id: 6,
    bankName: "Fortuneo",
    shortName: "Fortuneo",
    longName: "Fortuneo",
    logoUrl: "https://i.ibb.co/3yvz5r5/Fortuneo.png",
  },
  {
    id: 2,
    bankName: "Société Générale",
    shortName: "Société Générale",
    longName: "Société Générale",
    logoUrl: "https://i.ibb.co/Zx3sMcN/Societe-generale.png",
  },
  {
    id: 13,
    bankName: "BPE",
    shortName: "BPE",
    longName: "BPE",
    logoUrl: "https://i.ibb.co/4M66PC8/BPE.png",
  },
  {
    id: 9,
    bankName: "Allianz Banque",
    shortName: "Allianz Banque",
    longName: "Allianz Banque",
    logoUrl: "https://i.ibb.co/6vrCrV2/allianz-banque.png",
  },
  {
    id: 11,
    bankName: "Hello Bank",
    shortName: "HB",
    longName: "Hello Bank",
    logoUrl: "https://pic.clubic.com/v1/images/1811799/raw",
  },
  {
    id: 12,
    bankName: "FINTRO",
    shortName: "FINTRO",
    longName: "FINTRO",
    logoUrl:
      "https://play-lh.googleusercontent.com/Oorgagouy1L60iefnD4AjacHF2cXKQBiRvkzAvndGqH67-447XECxrgnJBsPw6J-TA",
  },
  {
    id: 15,
    bankName: "AXA Banque",
    shortName: "AXA Banque",
    longName: "AXA",
    logoUrl: "https://i.ibb.co/9b5D1VC/Axa.png",
  },
];
