/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DropDown({list}) {
  const [selected, setSelected] = useState(list[3])

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block ml-0.5 mb-2  font-bold text-effyis-purple">Banque</Listbox.Label>
          <div className="mt-1 relative focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3">
            <Listbox.Button className="h-20 relative w-full bg-white border border-gray-300 rounded-md  pl-3 pr-10 py-2 text-left cursor-default 
            shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3 sm:text-sm
             active:shadow-lg active:outline active:outline-violet-300  active:outline-3
            "
            
            >
              <span className="flex items-center">
                <img src={selected.logoUrl} alt="" className="flex-shrink-0 w-20 h-auto rounded-full" />
                <span className="ml-3 text-base font-semibold block truncate">{selected.bankName}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-72  rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {list.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-effyis-purple h-20 bg-violet-200' : 'text-gray-900',
                        'h-20 flex items-center justify-start cursor-default hover:text-effyis-purple hover:bg-violet-100 select-none relative py-12 pl-3 pr-9 '
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex flex-row justify-start items-center">
                            <div className="">
                            <img src={item.logoUrl} alt="bank" className="flex-shrink-0 w-20 h-auto rounded-full" />
                            </div>
                          
                          <span
                            className={classNames(selected ? 'font-bold' : 'font-semibold', 'text-base ml-3 block truncate')}
                          >
                            {item.bankName}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon color='purple' className="h-10 "  />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}