import React from "react";

// ===========================|| Open Banking Page ||=========================== //

const GetAccess = () => {
  return (
    <main className="relative w-full bg-white">
      <div className="p-16 border-solid border border-slate-400">
        <section className="space-y-4 text-center">
          <div className="space-y-6">
            <header className="text-2xl font-bold">Get Access</header>
            <h2 className="text-2xl font-light">
              Please request an invite to view the API specification.
            </h2>
            <div className="flex space-x-6 mt-10">
              <div className="w-1/2 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="NAME"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
              <div className="w-1/2 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="BUSINESS NAME"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
            </div>

            <div className="flex space-x-6">
              <div className="w-1/2 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="BUSINESS EMAIL"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
              <div className="w-1/2 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="BUSINESS WEBSITE"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
            </div>
            <div className="flex space-x-6">
              <div className="w-1/3 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="BUSINESS PHONE"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
              <div className="w-1/3 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="COUNTRY"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
              <div className="w-1/3 rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  type="text"
                  placeholder="PSP AUTHORISATION NUMBER (OPTIONAL)"
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
            </div>
            <div className="w-full rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
              <input
                type="text"
                placeholder="NATIONAL COMPETENT AUTHORITY (NCA)"
                className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
              />
            </div>
            <div className="w-full rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
              <input
                type="text"
                placeholder=" APPLICATION (OF BUSINESS WEBSITE) BEING DEVELOPED FOR PSD2 USE CASE (OPTIONAL)"
                className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
              />
            </div>

            <button className="w-full rounded-md border-b-4 border-b-blue-600 bg-blue-500 py-3 font-bold text-white hover:bg-blue-400 active:translate-y-[0.125rem] active:border-b-blue-400">
              Request Invite
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default GetAccess;
