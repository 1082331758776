/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { SwitchHorizontalIcon} from '@heroicons/react/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const modesPaiement =[
  'SEPA',
  'INST'
]

export default function DropDown({setTypePayment}) {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setTypePayment(selected)
  }, [selected]);

  return (
    <div className="">

    
    <Listbox  value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block ml-0.5 mb-2  font-bold text-effyis-purple">Mode Paiement</Listbox.Label>
          <div style={{zIndex: 40}} className="mt-1 relative focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3">
            <Listbox.Button className="py-3 relative w-full bg-white border border-gray-300 rounded-md  pl-3 pr-10 py-2 text-left cursor-default 
            shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3 sm:text-sm
             active:shadow-lg active:outline active:outline-violet-300  active:outline-3
            "
            >
              <span className="flex items-center">
                <SwitchHorizontalIcon color={'#A78BFA'} className='w-6'/>
                <span className="ml-3 text-base font-semibold text-gray-600 block truncate">
                  {selected != null ? (selected) : 'Mode Paiement..'}
                </span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mb-20 z-10 mt-1 w-full bg-white shadow-lg max-h-72  rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {modesPaiement.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-effyis-purple bg-violet-200' : 'text-gray-900',
                        'flex items-center justify-start cursor-default hover:text-effyis-purple hover:bg-violet-100 select-none relative  pl-3 pr-9 '
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex flex-row justify-start items-center py-2.5">
                            
                          <span
                            className={classNames(selected ? 'font-bold' : 'font-semibold', 'text-base ml-3 block truncate')}
                          >
                            {item}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon color='purple' className="h-6 "  />
                            
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    </div>
  )
}