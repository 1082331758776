import React from "react";
import ProductDetails from "./ProductDetails";

// ===========================|| Open Banking Page ||=========================== //

const ProductPage = () => {
  window.scrollTo(0, 0)

  return (
    <div className="  mb-6">
      <ProductDetails/> 
      
  
      {/*
       <div className=" mx-auto w-full md:w-full shadow p-5 rounded-md bg-white m-2 ">
        <h1 className="text-2xl py-2 font-bold tracking-tight text-gray-900  text-center ">
          Explorez nos produits
        </h1>

        <div className="relative">
          <div className="absolute flex items-center ml-2 h-full">
            <svg
              className="w-4 h-4 fill-current text-primary-gray-dark"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.8898 15.0493L11.8588 11.0182C11.7869 10.9463 11.6932 10.9088 11.5932 10.9088H11.2713C12.3431 9.74952 12.9994 8.20272 12.9994 6.49968C12.9994 2.90923 10.0901 0 6.49968 0C2.90923 0 0 2.90923 0 6.49968C0 10.0901 2.90923 12.9994 6.49968 12.9994C8.20272 12.9994 9.74952 12.3431 10.9088 11.2744V11.5932C10.9088 11.6932 10.9495 11.7869 11.0182 11.8588L15.0493 15.8898C15.1961 16.0367 15.4336 16.0367 15.5805 15.8898L15.8898 15.5805C16.0367 15.4336 16.0367 15.1961 15.8898 15.0493ZM6.49968 11.9994C3.45921 11.9994 0.999951 9.54016 0.999951 6.49968C0.999951 3.45921 3.45921 0.999951 6.49968 0.999951C9.54016 0.999951 11.9994 3.45921 11.9994 6.49968C11.9994 9.54016 9.54016 11.9994 6.49968 11.9994Z"></path>
            </svg>
          </div>

          <input
            type="text"
            placeholder="Search for products..."
            className="px-8 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
          ></input>
        </div>

        <div className="flex items-center justify-between mt-4">
          <p className="font-medium">Filters</p>

          <button className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
            Reset Filter
          </button>
        </div>
        <div>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
            <select className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
              <option value="">All Type</option>
              <option value="for-rent">Open Banking</option>
              <option value="for-sale">Fraude Prevention</option>
              <option value="for-sale">Payment Service</option>
            </select>

            <select className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm">
              <option value="">Status Type</option>
              <option value="fully-furnished">Deprecated</option>
              <option value="partially-furnished">Running</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4 mt-6 place-content-center">
        <hr className="w-1/3 border border-stone-500" />
        <div className="font-medium text-black text-center p-20">
          Service 1 
        </div>
        <hr className="w-1/3 border border-stone-500" />
      </div>
      <div className="flex mx-5 overflow-hidden">
        <div className="flex flex-col my-5 px-5 w-full  overflow-hidden sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
          <div className="mt-14  bg-white border-solid border border-slate-400 divide-y divide-slate-200 flex-1 ">

            <div className="px-5 pb-5">
              <a href="#">
                <h5 className="text-2xl py-2 font-bold tracking-tight text-gray-900">
                  Product title
                </h5>
              </a>

              <div className="flex justify-between items-center ">
                <span className="text-md font-normal text-gray-900 ">
                  Product description.
                </span>
              </div>
            </div>
            <div className="place-content-center flex flex-row ">
              <img
                className="w-60 h-40 p-4"
                src={Transactions}
                alt="transactions"
              />
              <img
                className="w-60 h-40 p-4"
                src={
                  "https://unigulf.sa/wp-content/uploads/2019/10/open-banking.png"
                }
                alt="s"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col my-5 px-5 w-full overflow-hidden sm:w-full md:w-full lg:w-1/2 xl:w-1/2  ">
          <div className="mt-14  bg-white border-solid border border-slate-400 divide-y divide-slate-200 flex-1">
            <div className="px-5 pb-5">
              <a href="#">
                <h5 className="text-2xl py-2 font-bold tracking-tight text-gray-900">
                  Product title
                </h5>
              </a>

              <div className="flex justify-between items-center">
                <span className="text-md font-normal text-gray-900">
                  Product description.
                </span>
              </div>
            </div>
            <div className="flex flex-row place-content-center mb ">
              <img className="w-60 h-40 p-4" src={Transactions} alt="pay" />
              <img
                className="w-60 h-40 p-4"
                src={Transactions}
                alt="banktransfert"
              />
            </div>
          </div>
        </div>
      </div>
      
      */}
   </div>
  );
};

export default ProductPage;
