import explicationImage from "../../../Assets/images/payment-gateway.png";
import { CurrencyEuroIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";

export default function ProductAdder({products, updateProducts, amount, setAmount}) {
    const addProduct = () => {
        updateProducts( (products) => [...products, {itemName:'', itemPrice:''}] )
    }
    const sum = (products) => {
        let somme = 0;
        for (let i = 0 ; i<products.length ; i++){
            somme += parseFloat(products[i].itemPrice);
        }
        return somme;
    }
    useEffect(() => {
        setAmount(sum(products))
    }, [products]);
    return (
        <div className='w-full flex flex-col gap-1'>
            {/*  Produits   */}
            <label
                htmlFor="firstname"
                className="mt-5 self-center font-bold ml-0.5 text-xl text-effyis-purple block "
            >
                Produits
            </label>
            
            <div
                style={{ width: "100%" }}
                className="flex flex-row justify-start  items-start"
            >
                
                {/* Product Name : */}
                <div
                style={{ width: "75%" }}
                className="mr-3 w-full flex flex-col justify-center items-start"
                >
                <label className="mb-1 font-bold font-mono ml-0.5 text-effyis-purple  ">
                    Nom du produit
                </label>

                
                </div>
                {/* Price : */}
                <div
                style={{ width: "25%" }}
                className="  w-full flex flex-col justify-center items-start"
                >
                <label
                    style={{ width: "100%" }}
                    className="w-44 mb-1 font-bold font-mono ml-0.5 text-effyis-purple block "
                >
                    Price
                </label>
                
                </div>
            </div>


            {
                products.map((product, index) =>
                    <div
                        style={{ width: "100%" }}
                        className="lg:-mb-6 sm:-mb-5 md:-mb-4 flex flex-row justify-start  items-start"
                    >
                        
                        {/* Product Name : */}
                        <div
                        style={{ width: "75%" }}
                        className="mr-3 w-full flex flex-col justify-center items-start"
                        >
                        <input
                            type="text"
                            style={{ width: "100%" }}
                            className=" px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                                    text-gray-600 font-medium "
                            placeholder="e.g. : Tele model X-131"
                            onChange={(e) => {
                                product.itemName = e.target.value;
                                updateProducts([...products]);
                            }}
                        />
                        </div>
                        {/* Price : */}
                        <div
                        style={{ width: "25%" }}
                        className="  w-full flex flex-col justify-center items-start"
                        >
                        
                        <input
                            type="number"
                            style={{ width: "100%" }}
                            className=" lg:pl-6 sm:pl-3 pr-1 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                                    text-gray-600 font-medium "
                            placeholder="Prix "
                            onChange={(e) => {
                                product.itemPrice = e.target.value;
                                updateProducts([...products]);
                            }}
                        />
                        <CurrencyEuroIcon
                            style={{
                            position: "relative",
                            }}
                            className="text-gray-500 lg:left-2/3 lg:bottom-10 lg:w-8  sm:w-5 sm:left-2/3 sm:bottom-8"
                        />
                        </div>
                    </div>
                )
            }
            

            <div
                        style={{ width: "100%" }}
                        className="-mb-5 flex flex-row justify-start  items-start"
                    >
                        
                        {/* Total Label : */}
                        <div
                        style={{ width: "75%", height:47}}
                        className="mr-3 flex flex-row  justify-between items-center"
                        >
                            <div
                                onClick={() => addProduct()}
                                style={{width:'75%', height:47}}
                                className="self-center tracking-widest mt-2 cursor-pointer rounded-full whitespace-nowrap inline-flex items-center justify-center px-4 py-1.5 border border-transparent 
                                                shadow-sm text-xl font-thin text-white bg-effyis-purple hover:bg-violet-600"
                                                
                            >
                                Ajouter
                            </div>
                            <label
                                style={{width:104}}
                                className="justify-self-end   self-end mb-1.5 lg:text-2xl sm:text-xl  font-thin font-mono ml-2 text-effyis-purple block "
                            >
                                Total : 
                            </label>
                        </div>

                        {/* Price : */}
                        <div
                        style={{ width: "25%" }}
                        className="  w-full flex flex-col justify-center items-start"
                        >
                        
                        <div
                            style={{ width: "100%" }}
                            className=" lg:pl-6 sm:pl-2 pr-1 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                                    text-gray-600 font-medium "
                            
                        > 
                            {amount}
                        </div>
                        <CurrencyEuroIcon
                            style={{
                            position: "relative",
                            }}
                            className="text-gray-500 lg:left-2/3 lg:bottom-10 lg:w-8  sm:w-5 sm:left-2/3 sm:bottom-8"
                        />
                        </div>
                    </div>

            
        </div>
    );
}
