import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../../Assets/lottie/squareLoading.json'
 
export default class UncontrolledLottie extends React.Component {
    render() {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
  
      return (
        <Lottie
            options={defaultOptions}
            height={'100%'}
            width={'100%'}

            speed={0.4}
        />
      );
    }
  }