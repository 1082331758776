/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({ title, listEtats, listTypes, tagsSelected, updateTagsSelected }) {
  //const [tagsSelected, updateTagsSelected] = useState([]);
  const [listEtat, updateListEtat] = useState(listEtats);
  const [listType, updateListType] = useState(listTypes);

  const removeTag = async (tag, type) => {
    updateTagsSelected(tagsSelected.filter((item) => item.name !== tag));
    if (type == "type"){
      updateListType((listType) => [...listType, tag]);
    } else {
      updateListEtat((listEtat) => [...listEtat, tag]);
    }
  };
  
  const addTag = (tagName, type) => {
    const tag = {
      name: tagName,
      type: type,
    };
    updateTagsSelected((tagsSelected) => [...tagsSelected, tag]);
    if (type == "type") {
      updateListType(listType.filter((item) => item !== tagName));
    } else {
      updateListEtat(listEtat.filter((item) => item !== tagName));
    }
  };

  const sortedArray = (arrayEntry) => {
    var obj = [...arrayEntry];
    obj.sort();
    return obj;
  };
  useEffect(() => {}, [tagsSelected, listEtat, listType]);

  return (
    <div className="flex flex-col  w-full" style={{zIndex:9}}>
      <div className='flex flex-row '>
        <Menu as="div" className=" inline-block text-left z-10">
          <p>
            <Menu.Button className="group-one text-black-400 block flex flex-nowrap mr-6">
              <p className="text-xl font-black  font-medium   group-one-hover:text-orange-400">
                Type
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black-400 group-one-hover:text-orange-400	 "
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Menu.Button>
          </p>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className=" origin-top-right absolute  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
              <div className="">
                {sortedArray(listType).map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <div
                        
                        onClick={() => addTag(item, "type")}
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        {item}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <Menu as="div" className="relative  inline-block text-left z-10">
          <div>
            <Menu.Button className="group-two block flex flex-nowrap mr-6">
              <p className="text-xl font-black text-black font-medium   group-two-hover:text-orange-400">
                {title}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black group-two-hover:text-orange-400	 "
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className=" origin-top-right absolute  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
              <div className="">
                {sortedArray(listEtat).map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <div
                        onClick={() => addTag(item, "etat")}
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        {item}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {tagsSelected.length > 0 ? (
        <div className="bg-gray-100 justify-self-center w-full self-center flex lg:flex-row md:flex-row sm:flex-col gap-2 mt-4 border-2 p-2 rounded pr-0">
            <p className='font-thin mr-4'>
                Filtre :
            </p>
          {tagsSelected.map((item, index) => (
            <div
              key={index}
              className={classNames(item.type == "type" ? 'bg-gray-500' : 'bg-gray-400',
                "flex flex-row mr-2 justify-between items-center  pl-2 pr-1 py-0 rounded-lg shadow-md ")}
            >
              <p className="font-thin font-mono text-white">
                {item.name}
                </p>
              <div
                className="ml-1 cursor-pointer"
                onClick={() => removeTag(item.name, item.type)}
              >
                <XCircleIcon width={15} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
