/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  CollectionIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  XIcon,
  CubeIcon,
} from "@heroicons/react/outline";
import EffyisLogoBlue from "../../../Assets/icons/effyis-logo-blue.png";
import Lodin from "../../../Assets/images/LodinForDevelopers.png";
import Signup from "../Wizard/Signup";
import { Link } from "react-router-dom";

export default function Example() {
  const [signupOpen, setSignupOpen] = useState(false);
  return (
    <Popover className=" bg-white bg-opacity-70  sticky top-0 z-10 border-b  backdrop-filter backdrop-blur-2xl ">
      {signupOpen ? (
        <Signup setOpen={setSignupOpen} open={signupOpen} />
      ) : (
        <></>
      )}
      <div className=" px-4 sm:px-6  ">
        <div className="flex justify-between  items-center py-3  lg:space-x-10 ">
          {/* IIIIIIIIIIIIIIIIIIIIIIII========   Logo + effyis logo container   ========IIIIIIIIIIIIIIIIIIIIIIII */}
          <div className="flex justify-start  :w-0 :flex-1 ">
            <Link to="/" className="flex flex-row">
              <img className="w-60 h-16 mr-2" src={Lodin} />
             
              
            </Link>
          </div>

          {/* IIIIIIIIIIIIIIIIIIIIIIII========   Mobile : Menu button ========IIIIIIIIIIIIIIIIIIIIIIII */}
          <div className="-mr-2 -my-2  lg:hidden ">
            <Popover.Button className="bg-effyis-black rounded-md p-2 inline-flex items-center  justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          {/* IIIIIIIIIIIIIIIIIIIIIIII========   Standard : Menu container ========IIIIIIIIIIIIIIIIIIIIIIII */}
          <div as="nav" className="hidden  lg:flex  lg:flex  space-x-6 ">
            <Link
              to="/DeveloperIndexPage"
              className="text-base font-medium hover:text-effyis-purple text-effyis-black"
            >
              Portail développeur{" "}
            </Link>
            <Link
              to="/SearchDocLandingPage"
              className="text-base font-medium hover:text-effyis-purple text-effyis-black"
            >
              {" "}
              Produits
            </Link>
            {/* <Link to='/ApiDetailsPage' className="text-base font-medium hover:text-violet-400 text-violet-100"> Test link </Link> */}
            <Link
              to="/OnboardingPage"
              className="text-base font-medium hover:text-effyis-purple text-effyis-black"
            >
              Sandbox{" "}
            </Link>
          </div>
          {/* IIIIIIIIIIIIIIIIIIIIIIII========   Buttons container ========IIIIIIIIIIIIIIIIIIIIIIII */}
          {/* <div className="hidden lg:flex items-center  justify-end  :w-0">
            <a
              href="#"
              className="invisible whitespace-nowrap inline-flex items-center justify-center px-4 py-1.5 border border-transparent 
                          rounded-md shadow-sm text-base font-medium text-violet-700 bg-white hover:bg-gray-200"
            >
              Se connecter
            </a>

            <a
              onClick={() => setSignupOpen(true)}
              href="#"
              className="invisible ml-5 whitespace-nowrap inline-flex items-center justify-center px-4 py-1.5 border border-transparent 
                          rounded-md shadow-sm text-base font-medium text-white bg-effyis-purple hover:bg-violet-600"
            >
              Inscription
            </a>
          </div> */}
        </div>
      </div>

      {/* IIIIIIIIIIIIIIIIIIIIIIII========   Mobile : Drop Menu ========IIIIIIIIIIIIIIIIIIIIIIII */}

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2  divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Popover.Button>
                  <Link to="/">
                    <img
                      className="h-8 w-auto"
                      src={EffyisLogoBlue}
                      alt="Workflow"
                    />
                  </Link>
                </Popover.Button>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <Popover.Button>
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon
                          className="flex-shrink-0 h-6 w-6 text-indigo-600"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </Link>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                {/* <a
                  href="#"
                  onClick={()=>setSignupOpen(true)}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-purple-600 hover:bg-purple-700"
                >
                  Inscription
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                 Déjà Client ?{" "}
                  <a href="#" className="text-purple-800 hover:text-purple-400">
                    Se connecter
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

const solutions = [
  {
    name: "Developer Portal",
    description: "Your customers' data will be safe and secure.",
    href: "/DeveloperIndexPage",
    icon: ShieldCheckIcon,
  },
  {
    name: "Produits",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "/SearchDocLandingPage",
    icon: CollectionIcon,
  },
  {
    name: "Sandbox",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "/OnboardingPage",
    icon: CubeIcon,
  },
];
const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];

const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];
