import SecondHeader from '../Components/Core/Header/SecondHeader'
import GatewayDetails from '../Components/Core/Gateway/GatewayDetails';
import GatewayCreateOrder from '../Components/Core/Gateway/GatewayCreateOrder';


export default function GatewayTest() {
  return (
    <div>
    <SecondHeader title={"Passerelle Paiement"} />
      
      <div style={{width:'100%'}} className=' grid sm:grid-cols-1  md:grid-cols-1 lg:grid-cols-2'>
        {/* Explication de la passerelle */}
        <GatewayDetails />
        <GatewayCreateOrder />
      </div>
    
  </div>
  );
}