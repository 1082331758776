import React, { useState } from 'react';
import {AnnotationIcon} from '@heroicons/react/outline'


export default function WizardStep4() {
    const [ setDescriptionPaiement] = useState('');
    const [ setDescriptionRemboursement] = useState('');
    
    
return (
    
    <div className="flex flex-col justify-start  items-center h-full  pb-5  bg-white-almost ">
        <div style={{width:'100%'}}
        >
            <h1 className='text-2xl font-bold text-effyis-purple font-serif mb-2' >
                Dernière étape !
            </h1>
            <p className='text-lg font-serif mb-2'>
                Veuillez entrer le contenu des informations de votre site ecom relatives à la :
            </p>
            {/* ================ Description du virement lors du paiment   ================ */}
            <div className="mb-5">
                <label htmlFor="firstname" className="font-bold text-effyis-purple ml-0.5 mb-2  block ">
                    Description du virement lors du paiment
                </label>
                <div className='-mt-6'>
                    <AnnotationIcon  
                        style={{position: 'relative', top: 37, left:12}}
                        className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                    <input type="text"
                    onChange={event => setDescriptionPaiement(event.target.value)}
                    className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
                    placeholder="e.g. ECOM SHOPNAME"
                    />
                </div>
            </div>


            {/* ================ Description du virement en cas de remboursement ================ */}
            <div className="mb-5">
                <label htmlFor="firstname" className="font-bold text-effyis-purple ml-0.5 mb-2  block ">
                    Description du virement en cas de remboursement
                </label>
                <div className='-mt-6'>
                    <AnnotationIcon  
                        style={{position: 'relative', top: 37, left:12}}
                        className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                    <input type="text"
                    onChange={event => setDescriptionRemboursement(event.target.value)}
                    className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
                    placeholder="e.g. REFUND SHOPNAME"
                    />
                </div>
            </div>


        </div>
        
    </div>
)
}


const banks = [
    {
        "id": 4,
        "bankName": "Crédit Mutuel de Bretagne",
        "shortName": "Crédit Mutuel de Bretagne",
        "longName": "Crédit Mutuel de Bretagne",
        "logoUrl": "https://i.ibb.co/nDB1gsd/Cr-dit-Mutuel-de-Bretagne.png",
    },
    {
        "id": 5,
        "bankName": "Crédit Mutuel du Sud-Ouest",
        "shortName": "Crédit Mutuel du Sud-Ouest",
        "longName": "Crédit Mutuel du Sud-Ouest",
        "logoUrl": "https://i.ibb.co/59dZQX0/Mutuel-du-Sud-Ouest.png",
    },
    {
        "id": 10,
        "bankName": "BCP BANQUE",
        "shortName": "BCP",
        "longName": "BCP BANQUE",
        "logoUrl": "https://i.ibb.co/NNpB8D4/Banque-BCP.png",
    },
    {
        "id": 1,
        "bankName": "BNP PARIS BAS",
        "shortName": "BNP PARIS BAS",
        "longName": "BNP PARIS BAS",
        "logoUrl": "https://i.ibb.co/phtS5Rd/BNP-Paribas.png",
    },
    {
        "id": 6,
        "bankName": "Fortuneo",
        "shortName": "Fortuneo",
        "longName": "Fortuneo",
        "logoUrl": "https://i.ibb.co/3yvz5r5/Fortuneo.png",
    },
    {
        "id": 2,
        "bankName": "Société Générale",
        "shortName": "Société Générale",
        "longName": "Société Générale",
        "logoUrl": "https://i.ibb.co/Zx3sMcN/Societe-generale.png",
    },
    {
        "id": 13,
        "bankName": "BPE",
        "shortName": "BPE",
        "longName": "BPE",
        "logoUrl": "https://i.ibb.co/4M66PC8/BPE.png",
    },
    {
        "id": 9,
        "bankName": "Allianz Banque",
        "shortName": "Allianz Banque",
        "longName": "Allianz Banque",
        "logoUrl": "https://i.ibb.co/6vrCrV2/allianz-banque.png",
    },
    {
        "id": 11,
        "bankName": "Hello Bank",
        "shortName": "HB",
        "longName": "Hello Bank",
        "logoUrl": "https://pic.clubic.com/v1/images/1811799/raw",
    },
    {
        "id": 12,
        "bankName": "FINTRO",
        "shortName": "FINTRO",
        "longName": "FINTRO",
        "logoUrl": "https://play-lh.googleusercontent.com/Oorgagouy1L60iefnD4AjacHF2cXKQBiRvkzAvndGqH67-447XECxrgnJBsPw6J-TA",
    },
    {
        "id": 15,
        "bankName": "AXA Banque",
        "shortName": "AXA Banque",
        "longName": "AXA",
        "logoUrl": "https://i.ibb.co/9b5D1VC/Axa.png",
    },
    
]