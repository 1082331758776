import React, { useState } from "react";

import { useSpring, animated } from "react-spring";
import ApiCard from "../Components/Core/Cards/ApiCard";
import ReactTooltip from "react-tooltip";
import {Link, useParams} from 'react-router-dom'

// ===========================|| Open Banking Page ||=========================== //
// ===========================|| Search Documentation Page ||=========================== //
const produits = [
    {
      type: 'produit',
      productName: 'Produit Marchand',
      publishDate:'30/09/2021',
      updateDate:'26/10/2021',
      description:"Ce produit permet d'effectuer les opérations concernant le marchant: création d'une commande, consulter les informations marchand, génération d'un access token.",
      version: '1.0.0',
      state:'published', // or published
      apis:[
        {
            id: 2,
            type: 'api',
            productName: 'API Marchand',
            version: '1.0.0',
            state:'published', // deprecated or published
        },
        {
            id: 3,
            type: 'api',
            productName: 'API Authentification',
            version: '1.0.0',
            state:'published', // deprecated or published
        },
      ]
    },
    {
      type: 'produit',
      productName: 'Produit Banque',
      publishDate:'30/09/2021',
      updateDate:'26/10/2021',
      description:"Ce produit permet d'effectuer les opérations concernant la banque: création requête de paiement, consulter les banques compatibles, consulter les informations des comptes bancaires, ainsi que l'hisorique des transactions pour chaque compte.",
      version: '1.0.0',
      state:'published', // or published
      apis:[
        {
            id: 1,
            type: 'api',
            productName: 'API PISP',
            version: '1.0.0',
            state:'published', // deprecated or published
        },
        {
            id: 5,
            type: 'api',
            productName: 'API AISP',
            version: '1.0.0',
            state:'published', // deprecated or published
        },
        {
            id: 6,
            type: 'api',
            productName: 'API Authentification',
            version: '1.0.0',
            state:'published', // deprecated or published
        },
      ]
    },
  ]

const ProductDetails = () => {
    window.scrollTo(0, 0)
    let {id} = useParams();
    const ID = parseInt(id)-1;

    // const [productDetails, setProductDetails] = useState(dataFromRoute.state.productDetails)
    const [isUp, setUp] = useState(false);
    const toggle = () => setUp((state) => !state);
    const { xyz } = useSpring({
        xyz: isUp ? [0, 0, 0] : [64, 0, 0],
        config: {
          // using duration instead of spring physics
          duration: 1000,
        },
      });
      React.useEffect(() => {
        toggle();
      }, []);

  return (
   <div className="overflow-x-hidden">
       {/* <a
        href='#'
        onClick={()=>console.log("btatta : " + productDetails.productName )}
       >
           TATA
       </a> */}
       <div className="flex flex-col lg:flex-row flex-wrap p-6 ">
               <div className="h-32 w-32 bg-effyis-purple p-1.5 m-2  mr-6 rounded-md">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-30 w-30 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                    </svg>
                </div>
                <div className=" flex flex-col">
                    <div className="mt-2">
                        
                        {
                            produits[ID].state ===  'published' ?
                            <div className="text-base inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-green-400 text-white rounded-full">
                            publié
                            </div>
                            :
                            <></>
                        }
                        
                        {
                            produits[ID].state ===  'deprecated' ?
                            <div className="text-base inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-red-500 text-white rounded-full">
                            obsolète
                            </div>
                            :
                            <></>
                        }

                        <div className="border border-transparent border-blue-600 ml-4 text-base inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-white text-violet-900 rounded-full">
                            {produits[ID].version}
                        </div>
                    </div>
                    <div className="lg:text-6xl sm:text-5xl  font-bold mt-4 mb-4 leading-10 ">{produits[ID].productName}</div>
                            <animated.div
                            style={{
                            transform: xyz.to((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
                            }}
                        >
                            <div className="ml-20 w-8 h-2 bg-black"></div>
                        </animated.div>
                        <div className="this is just a test ">

                        </div>
                    </div>          
        </div>
       
                <div className="flex flex-row gap-8 pl-8">
                    <div data-tip data-htmlFor="creationTooltip" className="font-medium justify-start items-center flex flex-row flex-wrap gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                        </svg>
                        {produits[ID].publishDate}
                    </div>
                    <ReactTooltip id="creationTooltip" place="bottom" effect="solid">
                        Création
                    </ReactTooltip>

                    <div data-tip data-htmlFor="updateTooltip" className="font-medium justify-start items-center flex flex-row flex-wrap gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                        </svg>
                        <h1 className="font-medium">{produits[ID].updateDate}</h1>
                    </div>
                    <ReactTooltip id="updateTooltip" place="bottom" effect="solid">
                        Mise à jour
                    </ReactTooltip>

                </div>
             
                <div className="flex flex-col lg:flex-row flex-wrap p-6">

                <div className="bg-black h-22 w-2"/>
                    <div className="bg-white-almost p-6 ">

                    <h1 className="font-bold text-xl">Description</h1>
                    <h2 className="font-thin">{produits[ID].description}</h2>
                    </div>
                    
                </div>
                <div className="text-3xl  font-bold mt-4 leading-10 ml-6">APIs incluses</div>
                
                
                <div className="grid lg:m-10 lg:px-10 sm:px-4 sm:m-4 lg:pb-4 sm:pb-2 gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-4  bg-gray-100">
                    {
                    produits[ID].apis.map((item, index)=>
                        <Link to={'/ApiDetailsPage/'+item.id}>
                            <ApiCard productDetails={item}/>
                        </Link>
                    )
                    }
                </div>
                
        </div>
    );
};

export default ProductDetails;
