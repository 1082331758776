import React from "react";
import SecondHeader from "../Components/Core/Header/SecondHeader";
// ===========================|| Open Banking Page ||=========================== //
import {Link} from 'react-router-dom'

const DeveloperIndexPage = () => {
    window.scrollTo(0, 0)
  return (
    <div className="">
        
        <SecondHeader title={""}/>
        <div className="p-6 mt-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pb-6  pt-6 gap-6 mx-auto ">
            <div className="bg-gray-100 p-5 rounded-lg shadow-lg">
                <div className="h-10 w-10 bg-green-500 rounded-full ml-auto mr-auto m-2 "/>
                <div className="p-2 text-black p-3 text-center">
                    MODULES
                </div>
                    
                <div className="p-2"> 
                    <p className="font-thin text-2xl  text-center ">
                        Je suis entrain d'utiliser un platforme e-commerce (Shopify, Opencart, PrestaShop, Wordpressp) 
                        et je veux une intégration simple et rapide 
                        <br/>
                        <span className="font-medium text-xl">
                            Ne nécessite aucune connaissance technique.
                        </span>
                    </p>    
                </div>    
                <div className="flex flex-nowrap  ml-auto mr-auto justify-center m-4">
                    <Link to='/ModulePage/fr-fr' className="rounded-lg  px-4 py-2 bg-effyis-purple m-2  hover:text-white text-white-almost ">
                        Explorer
                    </Link> 
                    
                </div>
                
            </div>

            <div className="bg-gray-100 p-2 rounded-lg shadow-lg">
                <div className="h-10 w-10 bg-orange-500 rounded-full ml-auto mr-auto m-2"/>
                <div className="p-2 text-black p-3 text-center">
                    Passerelle Paiement
                </div>
                
                <div className="p-2 flex flex-col"> 
                    <p className="font-thin text-2xl p-2 text-center">
                        Je suis entrain d'utiliser une solution personnalisée, Je veux une implémentation rapide et j'ai pas besoin d'une contrôle sur la séléction du mode paiement.
                    </p> 
                    <span className="text-center  font-medium text-xl">
                        Nécessite un peu des connaissances techniques.
                    </span>
                </div>
                <div className="flex flex-nowrap  ml-auto mr-auto justify-center m-4">
                    <Link to='/GatewayTest' className="rounded-lg  px-4 py-2 bg-effyis-purple m-2  hover:text-white text-white-almost mb-auto">
                        Payment Gateway
                    </Link>  
                </div>
            </div>

            <div  className="w-full bg-gray-100 p-5 lg:col-span-2 md:col-span-2 rounded-lg shadow-lg">
                <div className="h-10 w-10 bg-red-500 rounded-full ml-auto mr-auto m-2"/>
                <div className="p-2 text-black p-3 text-center">
                    API
                </div>
                <div className="p-2">
                    <p className="font-thin text-2xl  text-center">
                        Je veux un contrôle total sur la séléction du mode paiement. 
                        <br/>
                        <span className="font-medium text-xl">
                            Nécessite des connaissances techniques pour configurer l'intégration à zéro.
                        </span>
                    </p>    
                </div>    

                <div className="flex flex-nowrap  ml-auto mr-auto justify-center m-4">
                    <Link to="/DocumentationPage" className="rounded-lg  px-4 py-2 bg-effyis-purple m-2  hover:text-white text-white-almost ">
                        Référence API
                    </Link>  
                    <Link to='/SearchDocLandingPage' className="rounded-lg  px-4 py-2 bg-effyis-purple m-2  hover:text-white text-white-almost ">
                        Explorer
                    </Link>  
                </div>
            </div>
        </div>
    </div>
)};

export default DeveloperIndexPage;
