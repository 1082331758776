import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Core/Header/Header";
import {
  LandingPage,
  DocumentationPage,
  ApiDetailsPage,
  OnboardingPage,
  OpenBankingPage,
  ProductPage,
  SearchDocLandingPage,
  ProductDetails,
  DeveloperIndexPage,
  ModulePage,
  GatewayTest,
  Opencart,
  Magento,
  Prestashop,
  Woocommerce,
  Joomla,
  RedirectBankAuthPage,
} from "./Pages/index";

function App() {
  return (
    <Router>
      <Fragment>
        <Header />
        <Routes>
          <Route exact path="/" element={<LandingPage />}>
            LandingPage
          </Route>
          <Route path="/OnboardingPage" element={<OnboardingPage />}>
            Sandbox
          </Route>

          <Route path="/OnboardingPage/:subPage" element={<OnboardingPage />}>
            Sandbox
          </Route>
          <Route
            path="/RedirectBankAuthPage"
            element={<RedirectBankAuthPage />}
          >
            Page redirection
          </Route>

          <Route path="/OpenBankingPage" element={<OpenBankingPage />}>
            OpenBankingPage
          </Route>
          <Route path="/ProductPage" element={<ProductPage />}>
            ProductPage
          </Route>
          <Route
            path="/SearchDocLandingPage"
            element={<SearchDocLandingPage />}
          >
            SearchDocLandingPage
          </Route>
          <Route path="/ProductDetail/:id" element={<ProductDetails />}>
            Product Detail
          </Route>
          <Route path="/DeveloperIndexPage" element={<DeveloperIndexPage />}>
            Developer Portal
          </Route>
          <Route path="/ModulePage/:lang" element={<ModulePage />}>
            Module Page
          </Route>

          <Route path="/Opencart" element={<Opencart />}>
            Opencart Page
          </Route>

          <Route path="/magento" element={<Magento />}>
            Magento Page
          </Route>

          <Route path="/prestashop" element={<Prestashop />}>
            Prestashop Page
          </Route>

          <Route path="/woocommerce" element={<Woocommerce />}>
            Woocommerce Page
          </Route>

          <Route path="/DocumentationPage" element={<DocumentationPage />}>
            Documentation Page
          </Route>
          <Route path="/ApiDetailsPage/:id" element={<ApiDetailsPage />}>
            Api Details Page
          </Route>
          <Route path="/GatewayTest" element={<GatewayTest />}>
            Gateway Test
          </Route>
          <Route path="/Opencart" element={<Opencart />}>
            Opencart
          </Route>
          <Route path="/Joomla" element={<Joomla />}>
            Joomla
          </Route>
        </Routes>
        {/* <Footer /> */}
      </Fragment>
    </Router>
  );
}

export default App;
