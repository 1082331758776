import React from "react";
import {

  TemplateIcon,
  UserCircleIcon,
  CogIcon,
} from "@heroicons/react/outline";
import FixedSidebar from "../Components/Core/Sidebar/FixedSidebar";
import SandboxHome from "../Components/Core/Sandbox/SandboxHome";
import SandboxAccount from "../Components/Core/Sandbox/SandboxAccount";
import SandboxSettings from "../Components/Core/Sandbox/SandboxSettings";

import {Link, useParams} from 'react-router-dom'

// ===========================|| Open Banking Page ||=========================== //

const OnboardingPage = () => {
  let {subPage = 'home'} = useParams();
  window.scrollTo(0, 0);

  return (
    <div className="flex flex-row w-full">
      <FixedSidebar>
        {/* Profile Pic */}

        {/* Name */}
        <h1 className="text-2xl font-semibold mt-2">  </h1>
        {/* Role */}
        <h1 className="text-xl font-thin "></h1>

        

        {/* buttons */}
        <Link to="/OnboardingPage/home" className="flex flex-row justify-start items-center w-full  hover:bg-gray-300 cursor-pointer bg-gray-100  rounded px-3 py-2">
          <TemplateIcon color={"#4C1D95"} className="w-6" />
          <p
            style={{ color: "#4C1D95" }}
            className="ml-3 text-sm font-semibold"
          >
            Tableau de bord
          </p>
        </Link>

        <Link to="/OnboardingPage/account" className="flex flex-row justify-start mt-2 items-center w-full  hover:bg-gray-300 cursor-pointer bg-gray-100  rounded px-3 py-2">
          <UserCircleIcon color={"#4C1D95"} className="w-6" />
          <p
            style={{ color: "#4C1D95" }}
            className="ml-3 text-sm font-semibold"
          >
            Compte
          </p>
        </Link>

        <Link to="/OnboardingPage/settings" className="flex flex-row  justify-start mt-2 items-center w-full  hover:bg-gray-300 cursor-pointer bg-gray-100  rounded px-3 py-2">
          <CogIcon color={"#4C1D95"} className="w-6" />
          <p
            style={{ color: "#4C1D95" }}
            className="ml-3 text-sm font-semibold"
          >
            Paramètres
          </p>
        </Link>
      </FixedSidebar>
      

      {/* Place content here  */}
      
      {
        subPage === 'home' ? 
        <SandboxHome />
        : <></>
      }

      {
        subPage === 'account' ? 
        <SandboxAccount />
        : <></>
      }

{
        subPage === 'settings' ? 
        <SandboxSettings />
        : <></>
      }

      
      {/* end of content */}
    </div>
  );
};

export default OnboardingPage;
