import React from "react";
import ReactPlayer from 'react-player'
import Affichage from "../Assets/images/Opencart/affichage.PNG";
import Paramètres from "../Assets/images/Opencart/parametres.PNG";
import Connection from "../Assets/images/Opencart/connection.PNG";
import CheckoutExample from "../Assets/images/Opencart/checkoutexe.PNG";
import Qr from "../Assets/images/Opencart/qr.JPG";
import Bnc from "../Assets/images/Opencart/banque.JPG";
import Demo from "../Assets/videos/demoOC.mp4";


const Opencart = () => {
return (
    <div>

    <div className="flex lg:flex-col md:flex-col justify-center items-center lg:p-14 md:p-14 sm:py-5 bg-effyis-purple">
        <h5 className="text-white font-thin tracking-wider lg:text-5xl md:text-4xl sm:text-xl lg:p-6 md:p-6 sm:p-6 sm:text-center">Integration du plugin Lodin pour Opencart</h5>
    </div>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p>Ce plugin permet d'ajouter notre solution de paiement Lodin à votre boutique Opencart.</p>
        <p className="mb-4">En choisissant Lodin comme moyen de paiement, vous offrez à vos clients la possibilité de payer par virement bancaire aisément et en toute sécurité. 
        Notre application porte cette expérience à un niveau supérieur : en quelques clics seulement, les paiements sécurisés de vos clients seront effectués en un clin d'œil.</p>
        <p className="mb-4"><span className="font-bold text-gray-900">NB:</span> Ce plugin nécessite au minimum Opencart 3.0.</p>
    </div>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p className="mb-4">Voici une vidéo démonstrative sur le fonctionnement du plugin Lodin</p>
        <ReactPlayer url={Demo} controls width="100%" height={"100%"}/>
    </div>

    <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Installation</h2>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
       {/* <p className="mb-4">Ce plugin est disponible sur  <a className="text-effyis-purple hover:underline" href="#"> </a></p>*/}
        <p>Après avoir installé le plugin Lodin, il faut accéder à l'interface des extensions en choisissant le type payments puis cliquer sur le bouton vert installer afin de pouvoir l'utiliser.</p>
        <img className="max-w-full h-auto my-5" src={Affichage} alt="Affichage" />
    </div>

    <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Configuration</h2>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5">
        <p className="mb-4">Après l'activation du plugin, il va falloir se diriger vers la page des paramètres en appuyant sur "edit" pour pouvoir le configurer.</p>
        <img className="max-w-full h-auto my-5" src={Paramètres} alt="Paramètres" />
        <img className="max-w-full h-auto my-5" src={Connection} alt="Connection" />
        <p className="mb-4"> <span className="font-bold">Pour la partie général on trouve:</span>
        <ul>
         <li> <span className="font-bold text-gray-900"> Total </span> sert à définir le prix minimum pour atteindre le mode de paiement.</li>
         <li> <span className="font-bold text-gray-900"> Order Status </span> c'est le statut par défaut d'une commande une fois le module de paiement terminé.</li>
         <li> <span className="font-bold text-gray-900"> Geo zones </span> sont des zones personnalisées utilisées pour calculer les frais d'expédition et les taxes.</li>
         <li> <span className="font-bold text-gray-900"> Status </span> désactive ou active le mode de paiement. </li>
         <li> <span className="font-bold text-gray-900"> Sort Order </span> c'est la position du mode de paiement dans la devanture du magasin lorsqu'il est répertorié parmi tous les modes de paiement disponibles. </li>
         </ul>
         </p>
         <p className="mb-4"><span className="font-bold">Pour la partie connection: </span> <br/>
         Afin d'activer ce plugin il faut se connecter à Lodin en remplissant les deux champs <span className="font-bold text-gray-900"> Merchant id </span> et <span className="font-bold text-gray-900">  Merchant secret </span> afin de s'authentifier. </p>

        <p className="mb-4">Une fois vos données sont vérifiées et validées le plugin s'activera. il faut enregistrer la page de configuration en cliquant sur l'icone save afin de mémoriser les changements et d'afficher le bouton de paiement Lodin dans l'interface du paiement parmi les choix des modes de paiement disponibles.</p>
        <img className="max-w-full h-auto my-5 w-4/7" src={CheckoutExample} alt="Page du panier" />
    </div>

    <h2 className="text-effyis-purple font-normal tracking-wider lg:text-3xl lg:ml-20 lg:mt-16 lg:mb-5 md:text-3xl md:ml-20 md:mt-10 md:mb-5 sm:text-2xl sm:ml-10 sm:mt-5 sm:mb-5">Paiement</h2>

    <div className="lg:px-20 lg:my-10 md:px-20 md:my-10 sm:px-10 sm:my-5 flex flex-col">
        <p className="mb-4">En cliquant sur le bouton Lodin, vous allez être redirigé vers une interface contenant un ticket où les informations de la commande et les détails du commerçant sont affichés.</p>
        <img className="max-w-full h-auto my-5 self-center justify-self-center" src={Qr} alt="Paramètres" />
        <p className="mb-4">Après, il faut choisir la banque que vous souhaitez payer avec, choisir votre compte et terminer la procédure du paiement.</p>
        <img className="max-w-full lg:w-1/2 md:w-2/3 sm:w-full self-center my-5" src={Bnc} alt="Paramètres" />
    </div>

</div>
);
}

export default Opencart;