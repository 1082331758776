import { useRef, useContext, useEffect } from 'react';
import { useOnScreen } from './useOnScreen';
import { NavContext } from '../context/NavContext.jsx';

export const SetNav = (navLinkId) => {
	const ref = useRef(null);

	const { setActiveNav, setActiveNavLinkId } = useContext(NavContext);

	const isOnScreen = useOnScreen(ref);

	useEffect(() => {
		if (isOnScreen) {
			setActiveNavLinkId(navLinkId);
			setActiveNav(navLinkId.split('-')[0] )
		}
	}, [isOnScreen, setActiveNavLinkId, navLinkId]);

	return ref;
};