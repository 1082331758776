export default function SecurityProperties({properties}) {
    return (
        <div className='flex flex-col  justify-start items-start lg:ml-10 mt-2 w-full '>
            {
                properties.map((item, index) => (
                    <div className="flex  flex-row justify-center items-start">
                        <p className='w-20 text-right font-mono font-bold text-blackTextDark'> {item.name} </p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p className=' font-mono font-thin break-all'> {item.value} </p>
                    </div>
                ))
            }
        </div>        
    )
}
