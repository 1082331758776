import React from "react";

import ModuleCard from "../Components/Core/Modules/ModuleCard";
import SecondHeader from "../Components/Core/Header/SecondHeader";

const modules = [
    
    {
      name:"WooCommerce",
      title:"Le platforme le plus populaire",
      description1:"Woocommerce est le plugin de gestion e-commerce du CMS WordPress. Il est utilisé sur plus de 25% de sites e-commerce en France avec ses plus de 10 millions d’installations. Créé en 2011 WooCommerce est un plug-in puissant, fiable et sécurisé. Développés par plus de 350 développeurs dans le monde, WooCommerce offre ainsi la possibilité de personnaliser sa boutique en ligne à l’infini.",
      description2:"Le développement et la géstion d'un magasin WooCommerce nécessite des connaissances techniques. Nous vous recommandons d'avoir un partenaire qui s'assurera que votre boutique est à la hauteur des exigences techniques.",
      payments:'Yes',
      simpleCheckout:'Yes',
      demo:'Demo Shop',
      guides:'View',
      fees:'Gratuit',
      url:'/woocommerce',
      logo: require("../Assets/images/woocommerce-short.png"),
      pdf:require('../Assets/pdfs/Guide Plugin WordPress.pdf')
    },
    {
      name:"Open Cart",
      title:"Mettre en place plusieurs magasins à la fois.",
      description1:"OpenCart est un excellent choix pour les utilisateurs qui recherchent une solution de e-commerce simple. Mais aussi pour ceux qui souhaiteraient mettre en place plusieurs magasins à la fois. En effet, grace à OpenCart, vous pouvez le faire. Par ailleurs, vous pouvez gérer le tout depuis un seul tableau de bord comme le multisite de WordPress.",
      description2:"L’une des fonctionnalités intéressantes de OpenCart est la façon dont les sauvegardes sont intégrées dans la plateforme. Si vous avez des difficultés à faire des sauvegardes régulières, OpenCart peut être la solution !",
      payments:'Yes',
      simpleCheckout:'Yes',
      demo:'Demo Shop',
      guides:'View',
      fees:'Gratuit',
      url:'/opencart',
      logo: require("../Assets/images/OpenCart_logo2.png"),
      pdf:require('../Assets/pdfs/Guide Plugin OpenCart.pdf')

    },
    {
      name:"Joomla",
      title:"Commencer Rapidement et facilement", 
      description1:"Shopify est une plateforme CMS (Content Management System) visant à accompagner les e-commerçants dans la création de leur site et dans le développement de leur entreprise. Shopify mise sur la simplicité en proposant un outil accessible à tous afin que même les utilisateurs ayant peu de connaissances en informatique puissent s’y retrouver. Et sa méthode semble très bien fonctionner puisqu’il compte plus de 2 millions d’utilisateurs actifs en 2022.",
      description2:"Avec Shopify, vous pouvez utiliser le servicee paiement offert par Lodin",
      payments:'Oui',
      simpleCheckout:'Oui',
      demo:'Magasin Demo',
      guides:'Afficher',
      fees:'Gratuit',
      url:'/Joomla',
      logo: require("../Assets/images/joomla.png"),
      pdf:require('../Assets/pdfs/Guide Plugin Joomla.pdf')
    },
    {
      name:"PrestaShop",
      title:"Le plus populaire en France",
      description1:"PrestaShop est un système de gestion de contenus (ou CMS) lancé depuis 2007 et spécialisé dans la conception de sites de e-commerce. Leader sur le marché français, il fournit une solution tant adaptée pour les particuliers que pour les professionnels",
      description2:"Un commerçant peut par son biais créer gratuitement sa boutique en ligne sans connaissances techniques particulières. ",
      payments:'Yes',
      simpleCheckout:'Yes',
      demo:'Demo Shop',
      guides:'View',
      fees:'Gratuit',
      url:'/prestashop',
      logo: require("../Assets/images/PrestaShop-short.png"),
      pdf:require('../Assets/pdfs/Guide Plugin PrestaShop.pdf')
    },
    {
      name:"Magento",
      title:"La solution la plus puissante",
      description1:"Magento est une solution qui s’adresse à des sites web qui disposent d’un large catalogue de produits et d’une importante base de données. Même s’il s’agit d’une plateforme relativement intuitive, elle peut s’avérer un peu complexe pour ceux parmi vous qui n’ont jamais utilisé de CMS.",
      description2:"Si vous vous lancez à l’e-commerce et que vous possédez un catalogue d’articles relativement restreint. ",
      payments:'Yes',
      simpleCheckout:'Yes',
      demo:'Demo Shop',
      guides:'View',
      fees:'Gratuit',
      url:'/magento',
      logo: require("../Assets/images/magento-short.png"),
      
      pdf:require('../Assets/pdfs/Guide Plugin Magento.pdf')

    },
  
]
const ModulePage = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <SecondHeader title={"Intégration avec les outils de gestion de contenu"}>
        <div className="flex justify-start flex-row">
          <div className="lg:w-full sm:w-full md:w-full sm:p-2 md:p-5  lg:p-5">
            {/* Container shadowed */}
            <div className="flex flex-col justify-center items-start w-full bg-violet-100 sm:p-2 md:p-5  lg:p-5  md:col-span-2 rounded-lg shadow-lg">
              <div className="p-2">
                <div className="font-thin text-2xl  text-left ">

                  <p className="font-medium text-3xl">
                    Téléchargez nos plugins depuis votre CMS
                  </p>
                  <p className="mt-5 ">
                    En fonction de votre CMS suivez étape par étape le guide
                    utilisateur qui vous correspond.
                  </p>
                </div>
              </div>

              <div className="flex flex-wrap   justify-start gap-4  mt-4">
                {
                  modules.map((module, index) => 
                  // <a
                  // href={require('../Assets/pdfs/Guide Plugin OpenCart.pdf')}
                  // target='_blank'
                  // >
                    <ModuleCard pdfUrl={module.pdf} url={module.url} logo={module.logo} name={module.name} />
                  // </a>
                    
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </SecondHeader>
    </div>
  );
};

export default ModulePage;
