import React, { useState, useEffect } from 'react';
import {
    AtSymbolIcon,
    LockClosedIcon,

  } from '@heroicons/react/outline'

export default function WizardStep1() {
    const [passwordStrengthText, setPasswordStrengthText] = useState('Pas de mot de passe');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordHide, setPasswordHide] = useState(false)
    const [passwordMatchWarning, setPasswordMatchWarning] = useState(false)
    const togglePassword = () =>{
        setPasswordHide(!passwordHide);
    }

    useEffect(() => {
        passwordMatchCheck()
    },[passwordConfirm,password]);
    const checkPasswordStrength = () => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        let value = password;
        console.log('testing value :'+ password.length);

        if (strongRegex.test(value)) {
            setPasswordStrengthText("Mot de passe fort");
            setPasswordStrength(3);
        } else if(mediumRegex.test(value)) {
            setPasswordStrengthText("Peut être plus fort");
            setPasswordStrength(2);
            
        } else if (value.length < 6) {
            setPasswordStrengthText("Mot de passe trop court");
            setPasswordStrength(0);
        } else {
            setPasswordStrengthText("Trop faible");
            setPasswordStrength(1);
        }
    }
    
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        checkPasswordStrength();
    }
    
    const passwordMatchCheck = () =>{
        if(password.localeCompare(passwordConfirm) === 0){
            setPasswordMatchWarning(!passwordMatchWarning);
        }else{
            setPasswordMatchWarning(true);
        }
    }
    const handleChangePasswordConfirm = (event) => {
        setPasswordConfirm(event.target.value);
        console.log("compare value is : " + password.localeCompare(event.target.value))
        passwordMatchCheck()
    }
    
return (
    
    <div className="flex flex-col justify-start items-center h-full  pb-5  bg-white-almost " style={{width:'100%'}}>
        <div 
            // x-show.transition.in="step === 1"
        >
            {/* ================ Email   ================ */}
            <div className="mb-3">
                <label htmlFor="firstname" className="font-bold ml-0.5 mb-2 text-effyis-purple block ">
                    Email
                </label>
                <div className='-mt-6'>
                    <AtSymbolIcon  
                        style={{position: 'relative', top: 37, left:10}}
                        className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                    <input type="text"
                    
                    className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                    text-gray-600 font-medium "
                    placeholder="e.g. : jhonsmith@example.com"
                    />
                </div>
            </div>

            {/* ================ Password container ================ */}
            <div className="mb-2">

                <label htmlFor="password" className="ml-1 font-bold mb-1 text-effyis-purple block block">
                    Mot de passe
                </label>

                <div className="text-gray-600 ml-2 mb-4">
                    Veuillez créer un mot de passe sécurisé en suivant les critères ci-dessous:

                    <ul className="list-disc text-sm ml-4 ">
                        <li>Des lettres minuscules</li>
                        <li>Des nombres</li>
                        <li>Des lettres majuscules</li>
                        <li>Des caractères spéciaux</li>
                    </ul>	
                </div>

                {/* ======================={password match warning}======================= */}

                <h1 style={{display :(passwordMatchWarning ? 'block' : 'none')}} 
                    className='font-thin text-red-400 mb-1'
                >
                    * Le mots de passes ne se rassemblent pas
                </h1>


                {/* ======================={password}======================= */}
                <div className="relative">
                    <div className='-mt-6'>
                        <LockClosedIcon  
                            style={{position: 'relative', top: 37, left:12}}
                            className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                        <input 
                            type={passwordHide ? 'text' : 'password'}
                            className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                            text-gray-600 font-medium "
                            placeholder="Votre mot de passe..."
                            onChange={event => {
                                                handleChangePassword(event)
                                                if (event.target.value.length === 0) {
                                                    setPasswordStrengthText("Pas de mot de passe");
                                                    setPasswordStrength(0);
                                                }
                                            }}
                        />
                    </div>
                    
                        
                    <div className="absolute right-0 bottom-0 top-6 px-3 py-3 cursor-pointer" onClick={togglePassword} >	
                        <svg style={{display: ( passwordHide ? ('block') : ('none') )  }}  xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24"><path d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z"/></svg>
                        <svg style={{display: ( passwordHide ? ('none') : ('block') )  }} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24"><path d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z"/><path d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z"/></svg>
                    </div>
                </div>
                
                {/* ======================={confirm password}======================= */}
                <div className="relative mt-4">
                    <div className='-mt-6'>
                        <LockClosedIcon  
                            style={{position: 'relative', top: 37, left:12}}
                            className="flex-shrink-0 h-6 w-6 text-violet-400 " aria-hidden="true" />
                        <input 
                            type={passwordHide ? 'text' : 'password'}
                            className="w-full  pl-12 px-4 py-3 rounded-lg  shadow focus:shadow-lg focus:outline focus:outline-violet-300  focus:outline-3
                            text-gray-600 font-medium "
                            placeholder="Comfirmez votre mot de passe..."
                            onChange={event => {
                                                handleChangePasswordConfirm(event)
                                                console.log("password  is : " + password)
                                                console.log("password confirm is : " + event.target.value)
                                                
                                            }}
                        />
                    </div>
                    
                        
                    
                </div>


                <div className="flex items-center mt-4 h-3">

                    <div className="w-2/3 flex justify-between h-2">	
                        <div style={{backgroundColor: ((passwordStrength>0) ? ('#F87171') : ' ')}}   className="h-2 rounded-full mr-1 w-1/3 bg-gray-400"   />

                        <div style={{backgroundColor: ((passwordStrength>1) ? ('#FB923C') : '')}}  className="h-2 rounded-full mr-1 w-1/3 bg-gray-400" />

                        <div style={{backgroundColor: ((passwordStrength>2) ? ('#4ADE80') : ' ')}}  className="h-2 rounded-full w-1/3 bg-gray-400"  />
                    </div>
                    <div  className="text-gray-500 font-medium text-sm ml-3 ">
                        <span>{passwordStrengthText}</span>
                    </div>
                </div>




            </div>


            
            

            


        </div>
        
    </div>
)
}
