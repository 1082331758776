import React, { useState, useEffect } from "react";
import axios from "axios";

const TableTransactions = ({ accessToken, idBank }) => {
  const [account, setAccount] = useState({});

  const getAccountData = async () => {
    const options = {
      headers: {
        access_token: accessToken,
        TPP_ID_BANK: idBank,
      },
    };

    await axios
      .get(
        `https://dev.effyispayment.com/switch-sandbox-service/AISP/accountInformation`,
        options
      )
      .then((res) => {
        console.log(JSON.stringify(res.data));
        setAccount(res.data[0]);
      })
      .catch((error) => {
        console.log("error in table transaction : " + error);
      });
  };

  useEffect(() => {
    getAccountData();
  }, [idBank]);
  return (
    <div className="w-full == mx-auto bg-white shadow-lg rounded-sm border border-gray-200 mb-10">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Transactions</h2>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Motif</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Date</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Montant</div>
                </th>

                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Status</div>
                </th>
              </tr>
            </thead>
            {Object.keys(account).length === 0 ? (
              <></>
            ) : (
              <tbody className="text-sm divide-y divide-gray-100">
                {account.transactionInformationDTO.map((item, index) => (
                  <tr key={index}>
                    <td className="p-2">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">
                          {item.remittanceInformation.unstructured[0]}
                        </div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{item.bookingDate}</div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left font-medium text-green-500">
                        {item.transactionAmount.amount +
                          " " +
                          item.transactionAmount.currency}
                      </div>
                    </td>

                    <td className="p-2 whitespace-nowrap">
                      <div className="text-medium text-center">{item.status}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableTransactions;
